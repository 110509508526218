import { Alert, Snackbar } from '@mui/material';
import { ReactNode } from 'react';

export const SnackbarInfo = ({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) => (
  <Snackbar
    open={open}
    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    autoHideDuration={4000}
    onClose={onClose}
  >
    <Alert severity="info" variant="filled">
      {children}
    </Alert>
  </Snackbar>
);
