import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Box,
  Container,
  Grid,
  Drawer,
  Button,
  ListItemButton,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

interface Task {
  id: string;
  content: string;
}

interface TaskList {
  id: string;
  title: string;
  tasks: Task[];
}

const initialTaskLists: TaskList[] = [
  { id: 'todo', title: '未着手', tasks: [] },
  { id: 'inProgress', title: '着手中', tasks: [] },
  { id: 'done', title: '完了', tasks: [] },
];

function App() {
  const [taskLists, setTaskLists] = useState<TaskList[]>(initialTaskLists);
  const [newTask, setNewTask] = useState<string>('');
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourceListId = result.source.droppableId;
    const destinationListId = result.destination.droppableId;

    if (sourceListId === destinationListId) {
      const updatedTaskLists = [...taskLists];
      const sourceList = updatedTaskLists.find((list) => list.id === sourceListId);
      if (sourceList) {
        const [reorderedTask] = sourceList.tasks.splice(result.source.index, 1);
        sourceList.tasks.splice(result.destination.index, 0, reorderedTask);
        setTaskLists(updatedTaskLists);
      }
    } else {
      const updatedTaskLists = [...taskLists];
      const sourceList = updatedTaskLists.find((list) => list.id === sourceListId);
      const destinationList = updatedTaskLists.find((list) => list.id === destinationListId);
      if (sourceList && destinationList) {
        const [movedTask] = sourceList.tasks.splice(result.source.index, 1);
        destinationList.tasks.splice(result.destination.index, 0, movedTask);
        setTaskLists(updatedTaskLists);
      }
    }
  };

  const addTask = () => {
    if (newTask.trim() !== '') {
      const updatedTaskLists = [...taskLists];
      const todoList = updatedTaskLists.find((list) => list.id === 'todo');
      if (todoList) {
        const newTaskObj: Task = {
          id: `task-${Date.now()}`,
          content: newTask,
        };
        todoList.tasks.push(newTaskObj);
        setTaskLists(updatedTaskLists);
        setNewTask('');
      }
    }
    setDrawerOpen(false);
  };

  const deleteTask = (taskId: string, listId: string) => {
    const updatedTaskLists = [...taskLists];
    const list = updatedTaskLists.find((list) => list.id === listId);
    if (list) {
      list.tasks = list.tasks.filter((task) => task.id !== taskId);
      setTaskLists(updatedTaskLists);
    }
  };

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          {/* ドロワー */}
          <Drawer anchor="left" open={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
            <div style={{ width: '300px', padding: '20px' }}>
              <Typography variant="h5" gutterBottom>
                タスクを追加
              </Typography>
              <TextField
                label="タスクを入力"
                variant="outlined"
                fullWidth
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') addTask();
                }}
              />
              <Button variant="contained" color="primary" onClick={addTask} style={{ marginTop: '10px' }}>
                タスクを追加
              </Button>
            </div>
          </Drawer>
          {/* タスクリスト */}
          <Grid container spacing={2}>
            {taskLists.map((taskList) => (
              <Grid item key={taskList.id} xs={12} sm={6} md={4} lg={3}>
                <Paper elevation={3} style={{ padding: '20px', height: '100%' }}>
                  <Typography variant="h5" gutterBottom>
                    {taskList.title}
                  </Typography>
                  <Droppable droppableId={taskList.id} key={taskList.id}>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} {...provided.droppableProps}>
                        {taskList.tasks.map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItemButton component={Paper}>
                                  <ListItemText primary={task.content} />
                                  <IconButton onClick={() => deleteTask(task.id, taskList.id)} edge="end">
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemButton>
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {/* タスク追加ボタン */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDrawerOpen(true)}
            style={{ position: 'fixed', bottom: '20px', right: '20px' }}
          >
            タスクを追加
          </Button>
        </div>
      </DragDropContext>
    </Container>
  );
}

export default App;
