import { CircularProgress, Backdrop } from '@mui/material';

type TypeProps = {
  open?: boolean;
};

export const LoadingProgress = ({ open = false }: TypeProps) => (
  <Backdrop sx={{ color: '#fff' }} open={open}>
    <CircularProgress color="inherit" />
  </Backdrop>
);
