/**
 * 在庫一覧
 * 閲覧のみ
 *
 */

import { Typography, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { DataGrid, GridColDef, GridRowModel, GridToolbarContainer } from '@mui/x-data-grid';

import { colNum } from '../utils/gridColDefs';
import { ButtonLoading } from '../elements/Buttons';

import { gridAllDataLoader } from '../apis/grid';
import * as dateFns from 'date-fns';
import ButtonRemoteExcel from '../modules/ButtonRemoteExcel';

//  コンポーネント!!! - - - - - - - - - - - - - - -
export default function App() {
  //  パス指定の変数取得
  //const params = useParams();
  //  useParamsをuseSearchParamsに切替
  const [searchParams] = useSearchParams();
  const dateWorkStr = searchParams.get('d');
  //console.log(params);

  const [dateWork, setDateWork] = useState<Date | null>(
    dateWorkStr ? dateFns.parse(dateWorkStr as string, 'yyyyMMdd', new Date()) : new Date()
  );

  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //  データローディング関数を定義
  const fnLoadDataGrid = useCallback(async () => {
    setLoading(true);
    const res = await gridAllDataLoader({
      url_grid: 'grid_stock.php',
      params: { date: dateFns.format(dateWork as Date, 'yyyy-MM-dd') },
    });
    setRows(res.data.data);
    setLoading(false);
  }, [dateWork]);

  //  初期データ読み込み
  useEffect(() => {
    fnLoadDataGrid();
  }, [fnLoadDataGrid]);

  //  カラム定義
  const columns: GridColDef[] = [
    { field: 'id', headerName: '品番', width: 90, sortable: false },
    { field: 'name', headerName: '品名', width: 220, sortable: false },
    colNum({ field: 'qty', headerName: '製品', width: 70, sortable: false }),
    colNum({ field: 'stk1', headerName: '印刷', width: 70, sortable: false }),
    colNum({ field: 'stk2', headerName: '製筒', width: 70, sortable: false }),
    colNum({ field: 'stk3', headerName: 'ミシン', width: 70, sortable: false }),
  ];

  //  DataGridのToolbarコンポーネント
  const CustomToolbar = useCallback(() => {
    return (
      <>
        <GridToolbarContainer>
          <ButtonLoading onClick={fnLoadDataGrid} variant="outlined" sx={{ m: 1 }}>
            再読込
          </ButtonLoading>
          <ButtonRemoteExcel url_excel="grid_stock.php" params={{ flag_excel: 1 }} filename="在庫一覧.xlsx" />
        </GridToolbarContainer>
      </>
    );
  }, [fnLoadDataGrid]);

  return (
    <>
      <Box
        sx={{
          height: `calc(100vh - 100px)`,
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <Typography variant="h5" component="h2" sx={{ my: 2 }}>
          在庫一覧
        </Typography>
        <DataGrid
          rows={rows}
          loading={loading}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          hideFooter={true}
        />
      </Box>
    </>
  );
}
