import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { dictToValueOptions } from './funcs';
import { TypeStrDict } from '../types';
import { format, isValid } from 'date-fns';
import { ja } from 'date-fns/locale';

export const colNum = ({ field, headerName, ...rest }: GridColDef) => {
  return {
    headerName: headerName,
    field: field,
    align: 'right',
    type: 'number',
    valueFormatter: (params: GridValueFormatterParams) => Number(params.value!).toLocaleString(),
    ...rest,
  } as GridColDef;
};

export const colDict = ({ field, headerName, dict, ...rest }: GridColDef & { dict: TypeStrDict }) => {
  return {
    headerName: headerName,
    field: field,
    type: 'singleSelect',
    valueOptions: dictToValueOptions(dict),
    valueGetter: (params: GridValueGetterParams) => dict[params.value],
    ...rest,
  } as GridColDef;
};
/**
 * 日付フォーマット date-fns.formatでフォーマット
 * @param param0
 * @returns
 */
export const colDate = ({
  field,
  headerName,
  width = 140,
  fmt = 'yyyy/MM/dd(E)',
  ...rest
}: GridColDef & { fmt?: string; width?: number }) => {
  return {
    headerName: headerName,
    field: field,
    type: 'date',
    align: 'center',
    width: width,
    // 文字列の日付型をDate型に、できない場合はNULLを戻す('0000-00-00'は戻さない)
    valueGetter: ({ value }) => (value ? new Date(value) : null),
    //valueParser: ({ value }) => (value ? new Date(value) : null),
    //  valueGetterでDate型にしたのをformatterで戻す
    valueFormatter: ({ value }) => (isValid(value) ? format(value, fmt, { locale: ja }) : ''),
    ...rest,
  } as GridColDef;
};
