/**
 * フォーマット変換等色々関数定義
 */
import { format } from 'date-fns';
import { TypeStrDict, TypeValueOptions } from '../types';

export const formatDate = (date: number) => {
  return format(date, 'MMMM D, YYYY h:mm A');
};

export const dictToValueOptions = (dict: TypeStrDict): TypeValueOptions => {
  return Object.keys(dict).map((k) => {
    return { value: k, label: dict[k] };
  });
};

/* ReactHookFormでserver side validation 
https://www.carlrippon.com/react-hook-form-server-validation/ から */
export const addServerErrors = function <T>(
  errors: { [P in keyof T]?: string[] },
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T]!.join('. '),
    });
  });
};

export const sleep = (msec: number) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};

export const getBottomMachines = (machines: any) => {
  if (machines) {
    return machines
      .filter((v: any) => v.step === '3.0')
      .map((v: any) => {
        v.name = v.name.replace('ボトマー：', '');
        return v;
      });
  } else {
    return false;
  }
};
/* machines配列から指定stepの機械を抜き出す */
export const getMachines = (machines: any, step: string) => {
  if (machines) {
    return machines
      .filter((v: any) => v.step === step)
      .map((v: any) => {
        v.name = v.name.replace(/ボトマー：|製袋：|印刷：/, '');
        return v;
      });
  } else {
    return false;
  }
};
/* ログインのflag_adminごとに工程へのアクセス可否を判別 */
export const checkAuthStep = (flag_admin: string, step: string) => {
  if (flag_admin === '1') {
    return true;
  } else {
    const flag_prefix = flag_admin.substring(0, 1);
    if (flag_prefix !== '4') {
      return false;
    } else {
      const flag_suffix = flag_admin.substring(1);
      //  stepが3.0とかで来るのを3に変換
      const pstep = String(Number(step));
      if (pstep === '0') {
        return true;
      } else if (flag_suffix === pstep) {
        return true;
      } else {
        return false;
      }
    }
  }
};
