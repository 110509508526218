import { ReactNode, useContext } from 'react';
import { Grid, Button, Paper, Alert, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

//import LogoIcon from '../elements/LogoIcon';
import { axios, axiosThenRes, axiosCatchRes } from '../lib/axios';
import { AuthContext } from '../providers/AuthProvider';
import { checkAuthStep } from '../utils/funcs';

const GridItem = ({ children, title, link }: { children: ReactNode; title: string; link?: string }) => {
  return (
    <Grid item sm={6} xs={12}>
      <Paper sx={{ p: 2, m: 1, minHeight: '15em' }} elevation={3}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box>{children}</Box>
      </Paper>
    </Grid>
  );
};

/* メイン */
const HomePage = () => {
  //  テスト用、セッションのチェック
  const checkSession = () => {
    console.log('check session');
    const op = axios.get('get_session.php').then(axiosThenRes).catch(axiosCatchRes);
    console.log(op);
  };

  // useオブジェクト見て表示メニュー切り分け
  const { user } = useContext(AuthContext);
  const flg = String(user.flag_admin);

  return (
    <>
      {String(user.auth_id) === '43' && (
        <Alert severity="info">
          React(v17) ( <a href="https://ja.reactjs.org/">https://ja.reactjs.org/</a> ) と MUI(v5) ({' '}
          <a href="https://mui.com/">https://mui.com/</a> ) を使った開発。
          <br />
          バックエンドは従来通りPHP(v5)とMySQLを利用。認証周りもPHPセッションで対応。
          <Button onClick={checkSession} variant="outlined">
            Check Session
          </Button>
        </Alert>
      )}

      <Grid container sx={{ mt: 4 }}>
        {checkAuthStep(flg, '0') && (
          <GridItem title="印刷日報登録" link="proc_bottom">
            印刷日報登録処理
            <br />
            日付指定の印刷日報一覧と新規登録/登録済み編集
            <br />
            <Button
              variant="contained"
              component={Link}
              to="/proc_print"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '1')}
            >
              印刷日報登録
            </Button>
            <Button variant="contained" component={Link} to="/proc_print_list" sx={{ m: 1 }}>
              印刷日報一覧
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/reduce_material_print"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '1')}
            >
              印刷資材払出
            </Button>
          </GridItem>
        )}
        {checkAuthStep(flg, '0') && (
          <GridItem title="製筒日報登録" link="proc_bottom">
            製筒日報登録処理
            <br />
            日付指定の製筒日報一覧と登録済み編集
            <br />
            <Button
              variant="contained"
              component={Link}
              to="/proc_tube"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '2')}
            >
              製筒日報登録
            </Button>
            <Button variant="contained" component={Link} to="/proc_tube_list" sx={{ m: 1 }}>
              製筒日報一覧
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/reduce_material_tube"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '2')}
            >
              製筒資材払出
            </Button>
          </GridItem>
        )}
        {checkAuthStep(flg, '0') && (
          <GridItem title="底貼日報登録" link="proc_bottom">
            底貼日報登録処理、底貼不良詳細も入力。
            <br />
            日付指定の日報一覧と新規登録/登録済み編集
            <br />
            <Button
              variant="contained"
              component={Link}
              to="/proc_bottom"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '3')}
            >
              底貼日報登録
            </Button>
            <Button variant="contained" component={Link} to="/proc_bottom_list" sx={{ m: 1 }}>
              底貼日報一覧
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/reduce_material_bottom"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '3')}
            >
              底貼資材払出
            </Button>
          </GridItem>
        )}

        {checkAuthStep(flg, '0') && (
          <GridItem title="出荷前検査" link="proc_inspection">
            底貼日報登録処理、底貼不良詳細も入力。
            <br />
            日付指定の日報一覧と新規登録/登録済み編集
            <br />
            <Button
              variant="contained"
              component={Link}
              to="/proc_inspection"
              sx={{ m: 1 }}
              disabled={!checkAuthStep(flg, '20')}
            >
              出荷前検査登録
            </Button>
            <Button variant="contained" component={Link} to="/proc_inspection_list" sx={{ m: 1 }}>
              出荷前検査一覧
            </Button>
          </GridItem>
        )}

        {flg === '1' && (
          <GridItem title="運賃集計" link="delivery_cost">
            月ごとの運賃の登録処理。
            <br />
            配送業者ごとの出荷数一覧と1袋あたりの運賃計算
            <Button variant="contained" component={Link} to="/delivery_cost" sx={{ m: 1 }}>
              運賃一覧
            </Button>{' '}
          </GridItem>
        )}
        {flg === '1' && (
          <GridItem title="在庫増減チャート" link="stock_chart">
            （テスト中）在庫増減１ヶ月位のチャート表示。
            <br />
            <Button variant="contained" component={Link} to="/stock_chart" sx={{ m: 1 }}>
              在庫増減
            </Button>
          </GridItem>
        )}
        <Grid item sm={6} xs={12}>
          <Paper sx={{ p: 2, m: 1, minHeight: '15em' }} elevation={3}>
            <Typography variant="h6" gutterBottom>
              クレーム・コンプレイン一覧
            </Typography>
            <Box>
              クレーム・コンプレイン
              <br />
              別ウィンドウで表示
            </Box>

            <Button variant="contained" component="a" href="/yps/xs_complaints.php" target="_blank" sx={{ m: 1 }}>
              クレーム・コンプレイン一覧
            </Button>
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Paper sx={{ p: 2, m: 1, minHeight: '15em' }} elevation={3}>
            <Typography variant="h6" gutterBottom>
              在庫一覧
            </Typography>
            <Box>別ウィンドウで表示</Box>

            <Button variant="contained" component="a" href="/yps/xs_stocks.php" target="_blank" sx={{ m: 1 }}>
              在庫一覧
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
