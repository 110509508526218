/**
 * React-Routerのサイトから
 * https://reactrouter.com/docs/en/v6/examples/auth
 */

import { ReactNode, useState, createContext, useEffect, useCallback, useMemo, useContext } from 'react';
import { authCheck, authLogout, authLogin } from '../apis/auth';
import { useNavigate } from 'react-router-dom';
import { axios } from '../lib/axios';
import { LoadingContext } from '../providers/LoadingProvider';
import { sleep } from '../utils/funcs';
//  axiosのdefaultでCSRF-Tokenをセット
const setAxiosCSRF = (csrfToken: string) => {
  //console.log('set csrfToken: ' + csrfToken);
  axios.defaults.headers.post['X-CSRF-Token'] = csrfToken;
};

//  ユーザーデータの型
export type AuthUser = {
  auth_id: string;
  auth_name: string;
  auth_group: string;
  flag_admin: string;
};

//  AuthContextの型定義
type AuthContextType = {
  user: AuthUser;
  masters: any;
  isAuth: boolean;
  fnLogin: (login_id: string, password: string, callback?: VoidFunction) => void;
  fnLogout: (callback: VoidFunction) => void;
};

// AuthContextの定義
export const AuthContext = createContext<AuthContextType>(null!);

//  AuthProviderの作成、useEffectでauthCheckを実行しておく。
export default function AuthProvider({ children }: { children: ReactNode }) {
  const { setIsLoading } = useContext(LoadingContext);
  const noAuthUser = useMemo(() => {
    return { auth_id: '', auth_name: '', auth_group: '', flag_admin: '0' };
  }, []);
  //  認証済みかどうか
  const [isAuth, setIsAuth] = useState<boolean>(false);
  //  認証ユーザーデータのuseState
  const [user, setUser] = useState<AuthUser>(noAuthUser);
  const [masters, setMasters] = useState({});
  //  ローディング中
  const [loading, setLoading] = useState<boolean>(true);
  //  ログイン時のリダイレクト用
  const navigate = useNavigate();
  //const location = useLocation();

  /**
   * 認証チェック
   * apis/auth::authCheckをでphp/check_auth.phpでログインセッション情報を戻す
   * ここのuseEffectで使うのでuseCallback化
   */
  const checkAuth = useCallback(async () => {
    setIsLoading(true);
    setLoading(true);
    const authRes = await authCheck();
    //console.log(authRes);
    if (authRes.success && authRes.data.isAuth) {
      setIsAuth(true);
      setUser(authRes.data.user);
      setAxiosCSRF(authRes.data.csrfToken);
      setMasters(authRes.data.masters);
    } else {
      setIsAuth(false);
      setUser(noAuthUser);
      setAxiosCSRF('');
    }
    setIsLoading(false);
    setLoading(false);
  }, [noAuthUser, setIsLoading]);

  /**
   * ログイン処理
   * @param login_id
   * @param password
   * @param callback function()
   * @returns
   */
  const fnLogin = useCallback(
    async (login_id: string, password: string, errCallback = () => null) => {
      setIsLoading(true);
      await sleep(200);
      const authRes = await authLogin(login_id, password);

      setIsLoading(false);
      if (authRes.success && authRes.data.isAuth) {
        setIsAuth(true);
        setUser(authRes.data.user);
        setAxiosCSRF(authRes.data.csrfToken);
        setMasters(authRes.data.masters);
        //  認証OKならHOMEにリダイレクト
        navigate('/', { replace: true });
      } else {
        setIsAuth(false);
        setUser(noAuthUser);
        setAxiosCSRF('');
        //  ログイン失敗メッセージ的ななにか
        errCallback();
      }
    },
    [navigate, noAuthUser, setIsLoading]
  );

  /**
   * ログアウト・・・ここに書くべきか？
   */
  const fnLogout = useCallback(async () => {
    setIsLoading(true);
    await authLogout();
    await sleep(200);
    setIsAuth(false);
    setUser(noAuthUser);
    setIsLoading(false);
  }, [noAuthUser, setIsLoading]);

  /**
   * 初回ローディング時にuseEffectでcheckLogin()を行う
   */
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  //  Providerで戻すオブジェクト
  const value = { isAuth, user, masters, fnLogin, fnLogout, checkAuth };
  //return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
