/**
 * DB格納ではないkey-valueの辞書定義
 */

import { TypeStrDict } from '../types';
export const dictDivSupply: TypeStrDict = { '1': '切花', '2': '鉢花', '3': '資材' };
export const dictDivStock: TypeStrDict = { '1': '仕入', '2': '販売', '3': '材料', '4': '廃棄', '9': '棚卸' };
export const dictDivLabelStock: TypeStrDict = { '1': '納入', '2': '装着', '3': 'ロス', '9': '調整' };
export const dictStep: TypeStrDict = {
  '1.0': '印刷',
  '2.0': '製筒',
  '3.0': '底貼',
  '20.0': '出荷前検査',
};
export const dictStepSubProc: TypeStrDict = {
  '1.0': '印刷',
  '2.0': '製筒',
  '3.0': '底工程',
};
export const dictProcURL: TypeStrDict = {
  '1.0': '/proc_print',
  '2.0': '/proc_tube',
  '3.0': '/proc_bottom',
  '20.0': '/proc_inspection',
};
export const dictProcListURL: TypeStrDict = {
  '1.0': '/proc_print_list',
  '2.0': '/proc_tube_list',
  '3.0': '/proc_bottom_list',
  '20.0': '/proc_inspection_list',
};

export const dictReduceMaterialURL: TypeStrDict = {
  '1.0': '/reduce_material_print',
  '2.0': '/reduce_material_tube',
  '3.0': '/reduce_material_bottom',
};
