const SvgYngswLogo = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" overflow="auto" viewBox="0 0 1030.4 979.201" xmlSpace="preserve" {...props}>
    <path
      d="M339.201,0L518.4,256L697.6,0C889.601,76.801,1030.4,262.4,1030.4,480
	c0,243.202-172.8,448.002-403.199,499.201l-6.4-25.6C787.2,876.801,928,742.401,928,524.801c0-217.6-179.199-313.6-179.199-313.6
	l-160,192v576H448v-576l-160-192c0,0-179.199,102.4-179.199,313.6c0,217.6,140.799,352,307.199,428.801l-6.4,25.6
	C179.201,928.001,0,723.201,0,480C6.4,262.4,140.801,70.4,339.201,0z"
    />
  </svg>
);

export default SvgYngswLogo;
