/**
 * 底貼の日報設定
 */

import { ReactChild, useCallback, useContext, useEffect, useState } from 'react';
import { Typography, Container, Box, Grid, Button, Divider, Chip, Stack, Tab, Tabs } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { InputDate, InputNumber, InputText, InputSelect, InputAutocomplete } from '../elements/InputsHookForm';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yup, yupNumReq, yupStrReq, yupDateReq } from '../lib/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '../providers/AuthProvider';
import { LoadingContext } from '../providers/LoadingProvider';
import { ErrorDialogContext } from '../providers/ErrorDialogProvider';
import { request } from '../apis/request';
import * as dateFns from 'date-fns';
// アイコン
import SaveIcon from '@mui/icons-material/Save';
import { SnackbarInfo } from '../modules/SnackbarInfo';
import { dictStep, dictReduceMaterialURL } from '../utils/dicts';
import { getMachines, checkAuthStep, sleep } from '../utils/funcs';
import { debounce } from 'lodash';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import ReduceMaterialList from './components/ReduceMaterialList';
import { ButtonIconCalculate } from '../elements/Buttons';

//  数値入力GridItem + InputNumber
const GI = ({ control, label, name, ...rest }: any) => (
  <Grid item xs={6} sm={3} md={2}>
    <InputNumber control={control} label={label} name={name} {...rest} />
  </Grid>
);

//  資材払出登録項目のTYPE
type TypeProc = {
  date_work: Date;
  material: string;
  mst_machine: string;
  qty: number;
  memo: string;
};
// Resolver用のschema定義
const schema = yup.object().shape({
  date_work: yupDateReq,
  material: yupStrReq,
  mst_machine: yupNumReq,
  qty: yupNumReq,
});

const ProcDivider = ({ children }: { children: ReactChild }) => (
  <Divider textAlign="left" sx={{ my: 2 }}>
    {children}
  </Divider>
);

//  stepフラグによる

//  デバッグフラグ
const debug = false;

//  コンポーネント!!! - - - - - - - - - - - - - - -
export default function App({ step }: { step: string }) {
  //  useFormでフォームの設定
  const { control, handleSubmit, setValue, getValues, trigger, reset, clearErrors } = useForm<TypeProc>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: { date_work: new Date(), material: '' },
  });

  //  BottomNaviで一覧に移動する際に使うnavigate
  const navigate = useNavigate();

  //  routerのuseParamsでproc.idを取得
  const params = useParams();
  //  LoadingContext
  const { setIsLoading } = useContext(LoadingContext);
  //  ErrorDialogContextからshowErrorMessageを取得
  const { showErrorMessage } = useContext(ErrorDialogContext);

  // ReduceMateiralListに渡すdateWork
  const [dateWork, setDateWork] = useState<Date>(new Date());
  // ReduceMaterialList用のdrawカウンタ
  const [draw, setDraw] = useState(1);

  //  AuthContextから取得した作業機械の配列
  //const [machines, setMachines] = useState([]);
  const { masters, user } = useContext(AuthContext);
  const machines = getMachines(masters.machines, step);

  //  snackbarのuseState
  const [openSnackbar, setOpenSnackbar] = useState(false);

  //  資材(material)のAutocomplete用のoptions
  const [options, setOptions] = useState([]);
  //  資材の絞り込み条件param
  //  Tab関係
  let firstTab = 'paper';
  if (step === '3.0') {
    firstTab = 'bottoms';
  }
  const [tabValue, setTabValue] = useState(firstTab);
  const [filterParams, setFilterParams] = useState<any>({ step: step, divm: tabValue });

  //  資材選択肢(options)のfilterParams依存処理
  useEffect(() => {
    //  filterParams変わった場合はmaterialを空に
    setValue('material', '');
    //  search_materialで初期処理
    (async () => {
      const res = await request('search_material.php', filterParams);
      if (res.success) {
        setOptions(res.data);
      } else {
        setOptions([]);
      }
    })();
  }, [filterParams, setValue]);

  //  初期処理 - - - - - - - - - - - - -
  useEffect(() => {
    //console.log('init useEffect');
    if (!checkAuthStep(user.flag_admin, step)) {
      navigate('/');
    }
    if (params.id) {
      setIsLoading(true);
      (async () => {
        const res = await request('load_reduce_material.php', { id: params.id, step: step });
        setIsLoading(false);
        if (res.success) {
          res.data.data.date_work = dateFns.parse(res.data.data.date_work, 'yyyy-MM-dd', new Date());
          //  reset()メソッドで初期データをセット
          reset(res.data.data);
        } else {
          //  ローディングエラー表示
          showErrorMessage('データの呼び出しに失敗しました<br>' + res.msg);
        }
      })();
    } else {
      //  新規登録モード、初期化処理
      (async () => {
        setIsLoading(true);
        const values = getValues();
        reset({
          date_work: values.date_work,
          mst_machine: values.mst_machine,
          material: '',
        });
        await sleep(400);
        clearErrors('material');
        setIsLoading(false);
      })();
    }
  }, [params.id, step, user, getValues, navigate, reset, setIsLoading, setValue, showErrorMessage, clearErrors]);

  //  submit処理
  const onSubmit: SubmitHandler<TypeProc> = async (data) => {
    setIsLoading(true);
    //  一度anyでformDataに入れてから一部フォーマット変換
    const formData = { ...data } as any;

    //  日付と時間をフォーマット
    formData.date_work = data.date_work ? dateFns.format(data.date_work, 'yyyy-MM-dd') : null;
    //  step追加
    formData.step = step;

    //  asyncでデータ送信処理
    const res = await request('save_reduce_material.php', formData);

    setIsLoading(false);

    if (res.success) {
      setOpenSnackbar(true);

      if (formData.material_qty_id > 0) {
        //  更新処理時には新規モードに戻る
        navigate(dictReduceMaterialURL[step]);
      } else {
        reset({
          date_work: data.date_work,
          material: '',
          mst_machine: data.mst_machine,
        });
      }
      //  何故かmaterialの必須エラーが残るのでclearErrors
      await sleep(100);
      clearErrors('material');
      // ReduceMaterialListのdrawを更新して再ロード
      setDraw(draw + 1);
    } else {
      //  サーバ処理エラー
      if (res.message) {
        showErrorMessage(res.message);
      } else {
        showErrorMessage('資材払出処理に失敗しました');
      }
    }
  };
  //  submitエラー処理
  const onSubmitError = () => {
    console.log('handleSubmit Error');
    //showErrorMessage('資材払出SUBMITに失敗しました');
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //console.log('tabChange: ' + newValue);
    setTabValue(newValue);
    setFilterParams({ ...filterParams, divm: newValue });
  };

  //  資材絞り込みの遅延検索
  const debounceFilter = debounce((key: string, value: string) => {
    setFilterParams({ ...filterParams, [key]: value });
  }, 500);
  return (
    <>
      <Container sx={{ height: '100%' }}>
        <Stack direction="row" sx={{ my: 2 }} spacing={1}>
          <Typography variant="h5" component="h2">
            {dictStep[step]}資材払出
          </Typography>
          {params.id ? (
            <Chip color="info" label="修正" size="small" />
          ) : (
            <Chip color="error" label="新規" size="small" />
          )}
        </Stack>

        <Box>
          {debug && (
            <>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  reset();
                }}
              >
                reset
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  clearErrors('material');
                }}
              >
                reset material error
              </Button>
            </>
          )}
        </Box>
        <ProcDivider>基本</ProcDivider>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <InputDate
              control={control}
              label="作業日"
              name="date_work"
              fullWidth
              required
              onChange={(newDate: Date) => {
                setValue('date_work', newDate);
                setDateWork(newDate);
                //console.log(e);
                //console.log(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} md={8}>
            <InputSelect
              control={control}
              label="機械"
              name="mst_machine"
              items={machines}
              onChange={(e: any) => {
                //const machineID = e.target.value;
              }}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <ProcDivider>資材選択</ProcDivider>
        <Box
          border={2}
          sx={{ borderRadius: '4px', padding: '16px 8px', borderColor: (theme) => theme.palette.grey[200] }}
        >
          <Box sx={{ mb: 2 }}>
            {step === '1.0' && (
              <Tabs value={tabValue} onChange={onTabChange}>
                <Tab label="原紙" value="paper" />
                <Tab label="インク" value="ink" />
              </Tabs>
            )}
            {step === '2.0' && (
              <Tabs value={tabValue} onChange={onTabChange}>
                <Tab label="原紙" value="paper" />
                <Tab label="ポリ" value="poly" />
                <Tab label="製筒資材" value="tubes" />
              </Tabs>
            )}
            {step === '3.0' && (
              <Tabs value={tabValue} onChange={onTabChange}>
                <Tab label="底貼資材" value="bottoms" />
              </Tabs>
            )}
          </Box>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item md={2}>
              <Box
                sx={{
                  borderRadius: '4px',
                  padding: '16px 4px',
                  backgroundColor: 'lightGrey',
                  textAlign: 'right',
                  //backgroundColor: (theme) => theme.palette.info.main,
                }}
              >
                検索条件：
              </Box>
            </Grid>
            <Grid item md={2}>
              <InputText
                label="資材名"
                name="material_name"
                control={control}
                onChange={(e: any) => {
                  debounceFilter('name', e.target.value);
                }}
              />
            </Grid>
            {tabValue === 'paper' && (
              <Grid item md={2}>
                <InputNumber
                  label="原紙巾"
                  name="rollwidth"
                  control={control}
                  onChange={(e: any) => {
                    debounceFilter('rollwidth', e.target.value);
                  }}
                />
              </Grid>
            )}
            {tabValue === 'paper' && (
              <Grid item md={2}>
                <InputNumber
                  label="連量"
                  name="weight"
                  control={control}
                  onChange={(e: any) => {
                    debounceFilter('weight', e.target.value);
                  }}
                />
              </Grid>
            )}
            {tabValue === 'poly' && (
              <Grid item md={2}>
                <InputNumber
                  label="ポリ厚"
                  name="thick"
                  control={control}
                  onChange={(e: any) => {
                    debounceFilter('thick', e.target.value);
                  }}
                />
              </Grid>
            )}

            <Grid item md={12}>
              <InputAutocomplete
                control={control}
                setValue={setValue}
                trigger={trigger}
                options={options}
                label="資材"
                name="material"
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <ProcDivider>払出数量</ProcDivider>

        <Grid container spacing={2} alignItems="center">
          <GI control={control} label="払出数" name="qty" required />
          <Grid item md={8} xs={12}>
            <InputText control={control} label="備考" name="memo" fullWidth />
          </Grid>
          <Grid item md={2}>
            <Button variant="contained" color="success" size="large" disabled startIcon={<AddAPhotoIcon />} fullWidth>
              カメラ
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            my: 2,
            //backgroundColor: (theme) => theme.palette.info.light,
          }}
        >
          {params.id && (
            <Button
              variant="contained"
              color="info"
              size="large"
              sx={{ m: 1 }}
              onClick={() => {
                navigate(dictReduceMaterialURL[step]);
              }}
            >
              新規登録モードに戻る
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ m: 1 }}
            startIcon={<SaveIcon />}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          >
            保存
          </Button>
        </Box>
        <ProcDivider>資材払出履歴</ProcDivider>
        <ReduceMaterialList step={step} dateWork={dateWork} draw={draw} />
        {/* ここの下に資材払出グリッドを - - - - - - -  */}
      </Container>

      <SnackbarInfo
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        資材払出登録が完了しました
      </SnackbarInfo>
    </>
  );
}
