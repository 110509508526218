/**
 * react-pdfを使って製品規格表PDFをインライン表示
 */

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Alert, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../providers/LoadingProvider';
import { request } from '../apis/request';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDFItem = () => {
  const { item } = useParams();
  const { setIsLoading } = useContext(LoadingContext);
  const [path, setPath] = useState('/yps/r/test_pdf/item_23904804.pdf');

  console.log('item:' + item);

  useEffect(() => {
    //  最初はLoading中に
    setIsLoading(true);
    //  表示するPDFパスを取得
    (async () => {
      const res = await request('get_item_pdf.php', { item: item });
      console.log(res);
      if (res.success) {
        setPath(res.data.path_pdf);
      } else {
        setPath('');
      }
      setIsLoading(false);
    })();
  }, [setIsLoading, item]);
  return (
    <Paper elevation={2} sx={{ width: '100%', overflow: 'auto' }}>
      <Document
        file={path}
        options={{
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        error={<Alert severity="error">製品規格表を読み込めませんでした</Alert>}
      >
        <Page pageNumber={1} height={1100} />
      </Document>
    </Paper>
  );
};

export default PDFItem;
