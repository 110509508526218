/**
 * ローディング中の
 */
import { createContext, useState, ReactNode } from 'react';
import { LoadingProgress } from '../elements/Progress';

type TypeLoadingContext = {
  isLoading: boolean;
  setIsLoading: (b: boolean) => void;
};

//  Context作成
export const LoadingContext = createContext<TypeLoadingContext>({
  isLoading: false,
  setIsLoading: (isLoading: boolean) => undefined,
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const value = { isLoading, setIsLoading };

  return (
    <LoadingContext.Provider value={value}>
      {children}
      <LoadingProgress open={isLoading} />
    </LoadingContext.Provider>
  );
};
