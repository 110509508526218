/**
 * Shipping.tsx
 * 出荷処理、ORDERS抜きで処理
 */

import { ReactChild, useContext, useEffect, useState } from 'react';
import { Typography, Container, Box, Grid, Button, Divider, Chip, Stack } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { InputDate, InputNumber, InputText, InputSelect } from '../elements/InputsHookForm';
import FixedBottomNavi from '../elements/FixedBottomNavi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yup, yupNum, yupStrReq, yupDateReq } from '../lib/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '../providers/AuthProvider';
import { LoadingContext } from '../providers/LoadingProvider';
import { ErrorDialogContext } from '../providers/ErrorDialogProvider';
import { loadFormData, sendFormData } from '../apis/form';
import * as dateFns from 'date-fns';
// アイコン
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ListIcon from '@mui/icons-material/List';
import { ButtonNew } from '../elements/Buttons';
import { SnackbarInfo } from '../modules/SnackbarInfo';

//  出荷登録項目のTYPE
type TypeProc = {
  date_work: Date;
  item: string;
  qty: number;
  memo: string;
};
// Resolver用のschema定義
const schema = yup.object().shape({
  item: yupStrReq,
  date_work: yupDateReq,
  qty: yupNum,
});

const ProcDivider = ({ children }: { children: ReactChild }) => (
  <Divider textAlign="left" sx={{ my: 2 }}>
    {children}
  </Divider>
);

//  デバッグフラグ
const debug = false;

//  コンポーネント!!! - - - - - - - - - - - - - - -
export default function App() {
  //  useFormでフォームの設定
  const { control, handleSubmit, setValue, getValues, reset } = useForm<TypeProc>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: { date_work: new Date(), item: '' },
  });

  //  BottomNaviで一覧に移動する際に使うnavigate
  const navigate = useNavigate();

  //  routerのuseParamsでproc.idを取得
  const params = useParams();
  //  routerのuseSearchParams
  const [searchParams] = useSearchParams();

  //  LoadingContext
  const { setIsLoading } = useContext(LoadingContext);
  //  ErrorDialogContextからshowErrorMessageを取得
  const { showErrorMessage } = useContext(ErrorDialogContext);

  //  AuthContextから取得した作業機械の配列
  //const [machines, setMachines] = useState([]);
  const { masters } = useContext(AuthContext);
  //  snackbarのuseState
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (params.id) {
      //  更新モード、データローディング処理
      setIsLoading(true);
      (async () => {
        const res = await loadFormData('load_shipping.php', { id: params.id });
        setIsLoading(false);
        if (res.success) {
          res.data.data.date_work = dateFns.parse(res.data.data.date_work, 'yyyy-MM-dd', new Date());
          //  reset()メソッドで初期データをセット
          reset(res.data.data);
        } else {
          //  ローディングエラー表示
          showErrorMessage('データの呼び出しに失敗しました<br>' + res.msg);
        }
      })();
    } else {
      //  新規登録モード、初期化処理
      setIsLoading(true);
      setTimeout(() => {
        const values = getValues();
        reset({
          date_work: values.date_work,
        });

        const dateWorkStr = searchParams.get('d');

        if (dateWorkStr) {
          const dateWork = dateFns.parse(dateWorkStr, 'yyyyMMdd', new Date());
          setValue('date_work', dateWork);
        }
        setIsLoading(false);
      }, 500);
    }
  }, [params.id, searchParams, getValues, reset, setIsLoading, setValue, showErrorMessage]);

  //  submit処理
  const onSubmit: SubmitHandler<TypeProc> = (data) => {
    setIsLoading(true);
    //  一度anyでformDataに入れてから一部フォーマット変換
    const formData = { ...data } as any;

    //  日付と時間をフォーマット
    formData.date_work = data.date_work ? dateFns.format(data.date_work, 'yyyy-MM-dd') : null;

    //  asyncでデータ送信処理
    (async () => {
      const res = await sendFormData('save_shipping.php', formData);
      //console.log(res);
      setIsLoading(false);

      if (res.success) {
        setOpenSnackbar(true);
        navigate(
          '/shipping_list/?d=' +
            (data.date_work ? dateFns.format(data.date_work, 'yyyyMMdd') : dateFns.format(new Date(), 'yyyyMMdd'))
        );
      } else {
        //  サーバ処理エラー
        if (res.message) {
          showErrorMessage(res.message);
        } else {
          showErrorMessage('日報登録処理に失敗しました');
        }
      }
    })();
  };
  //  submitエラー処理
  const onSubmitError = () => {
    console.log('handleSubmit Error');
  };

  return (
    <>
      <Container sx={{ height: '100%' }}>
        <Stack direction="row" sx={{ my: 2 }} spacing={1}>
          <Typography variant="h5" component="h2">
            出荷処理
          </Typography>
          {params.id ? (
            <Chip color="info" label="修正" size="small" />
          ) : (
            <Chip color="error" label="新規" size="small" />
          )}
        </Stack>

        <Box>
          {debug && (
            <>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  console.log(getValues());
                }}
              >
                Check Data
              </Button>
            </>
          )}
        </Box>
        <ProcDivider>基本 *</ProcDivider>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <InputDate control={control} label="作業日" name="date_work" fullWidth required />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputSelect
              control={control}
              items={masters.items}
              onChange={(e: any, option: any) => {
                //console.log('onChangeCustommmm');
                //console.log(option);
              }}
              label="商品"
              name="item"
              required
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <InputNumber control={control} label="出荷数" name="qty" fullWidth />
          </Grid>

          <Grid item md={12} xs={12}>
            <InputText control={control} label="備考" name="memo" fullWidth />
          </Grid>
        </Grid>

        <Box sx={{ height: 100 }}></Box>
      </Container>
      <FixedBottomNavi>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{ m: 1 }}
            startIcon={<ListIcon />}
            onClick={() => {
              //  作業日と機械をキープしてListに

              const dateWork = getValues('date_work');
              let path = '/packing_list/?d=' + dateFns.format(dateWork, 'yyyyMMdd');
              navigate(path);
            }}
          >
            一覧
          </Button>
          {params.id && (
            <ButtonNew
              variant="contained"
              color="error"
              sx={{ m: 1 }}
              onClick={() => {
                const dateWork = getValues('date_work');
                navigate('/packing/?d=' + dateFns.format(dateWork, 'yyyyMMdd'));
                //reset
                //navigate(0);
              }}
            >
              新規モード
            </ButtonNew>
          )}

          <Button
            variant="contained"
            color="warning"
            size="large"
            sx={{ m: 1 }}
            startIcon={<CancelIcon />}
            onClick={() => {
              const values = getValues();
              reset({
                date_work: values.date_work,
              });
            }}
          >
            リセット
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ m: 1 }}
            startIcon={<SaveIcon />}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          >
            保存
          </Button>
        </Box>
      </FixedBottomNavi>
      <SnackbarInfo
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        日報登録が完了しました
      </SnackbarInfo>
    </>
  );
}
