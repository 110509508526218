/**
 * react-pdfを使って製品規格表PDFをインライン表示
 */

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Paper } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDFTest = () => {
  return (
    <Paper elevation={3} sx={{ width: '100%', overflow: 'auto' }}>
      <Document
        file="/yps/r/test_pdf/item_23904804.pdf"
        options={{
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
      >
        <Page pageNumber={1} height={1100} />
      </Document>
    </Paper>
  );
};

export default PDFTest;
