/**
 * bulletproofから
 * axiosをCreateしてaxiosとして返す(wrapper)
 */
import Axios from 'axios';
import { AxiosResponse } from 'axios';

/* config(.env)からAPI_URLの取得 */
import { API_URL } from '../config';

/* Axiosのwrapper */
export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
  auth: { username: 'achop', password: 'olomogef' },
});
axios.defaults.headers.post['X-Test-Token'] = 'omoloh';

/* Axiosのthen(resolve)のレスポンス処理 */
export const axiosThenRes = (res: AxiosResponse) => {
  //  戻りのフォーマット
  const ret: any = { success: false, data: [], error: [], total: 0, message: '' };

  if (typeof res !== 'object') {
    ret.message = 'サーバが不明な応答を返しました';
  } else if (res.status !== 200) {
    ret.message = '正しいレスポンスが得られませんでした(status:' + res.status + ')';
  } else if (res.data.success === false) {
    if (res.data.message) {
      ret.message = res.data.message;
    } else if (res.data.msg) {
      ret.message = res.data.msg;
    } else {
      ret.message = 'データの取得に失敗しました';
    }
  } else if (typeof res.data !== 'object') {
    console.log('data type is not object');
    ret.message = 'データ形式が不正です';
  } else {
    ret.success = true;
    ret.data = res.data;
  }
  return ret;
};

/* Axiosのthen(resolve)のレスポンス処理 */
export const axiosThenBlobRes = (res: AxiosResponse) => {
  //  戻りのフォーマット
  const ret: any = { success: false, data: '', error: [], total: 0, message: '' };

  if (typeof res !== 'object') {
    ret.message = 'サーバが不明な応答を返しました';
  } else if (res.status !== 200) {
    ret.message = '正しいレスポンスが得られませんでした(status:' + res.status + ')';
  } else {
    ret.success = true;
    ret.data = res.data;
  }
  return ret;
};

export const axiosCatchRes = (err: any) => {
  const ret: any = { success: false, message: '' };
  if (err.isAxiosError) {
    ret.message = err.message;
  } else {
    ret.message = '不明なエラーです';
  }
  return ret;
};
