import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import { APP_TITLE } from './config';
//import reportWebVitals from './reportWebVitals';

//  .env確認
const DEVMODE = process.env.REACT_APP_DEVMODE as string;
//console.log('devmode:' + DEVMODE);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>{APP_TITLE}</title>
      </Helmet>
      <Router />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
/*
ReactDOM.render(<Router />, document.getElementById('root'));
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
