import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useContext, Suspense } from 'react';
//  Localization
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
//  providers関係
import { AuthContext } from './providers/AuthProvider';
import { LoadingProvider } from './providers/LoadingProvider';
import { ErrorDialogProvider } from './providers/ErrorDialogProvider';
import AuthProvider from './providers/AuthProvider';
//  pages配下
import NotFound from './pages/Page404';
import Login from './pages/Login';
import AuthFrame from './pages/AuthFrame';
import Proc from './pages/Proc';
import ProcSubProc from './pages/ProcSubProc';
import ProcList from './pages/ProcList';
import ProcListSubProc from './pages/ProcListSubProc';
import DeliveryCost from './pages/DeliveryCost';
import StockChart from './pages/StockChart';
import StockList from './pages/StockList';
import Packing from './pages/Packing';
import PackingList from './pages/PackingList';
import Shipping from './pages/Shipping';
import ShippingList from './pages/ShippingList';
import HomePage from './pages/HomePage';
import HomeSubProc from './pages/HomeSubProc';
import Complaints from './pages/Complaints';
import PDFTest from './pages/PDFTest';
import PDFItem from './pages/PDFItem';
import ReduceMaterial from './pages/ReduceMaterial';

import TestAgg from './pages/TestAgg';
import TestDnD from './pages/TestDnD';
import ReduceMaterialList from './pages/components/ReduceMaterialList';

import ErrorBoundary from './modules/ErrorBoundary';

import { BASE_URL, APP_MODE } from './config';

// ----------------------------------------------------------------------
/**
 * https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
 * を参考にして実装
 * 認証必要なページはRouteのelementのコンポーネントをコレで括る
 * @param param0
 * @returns
 */
const RequireAuth = ({ children }: { children: any }) => {
  const { isAuth } = useContext(AuthContext);
  if (!isAuth) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default function Router() {
  //  最下段にLoadingProgressをセットしておくのでBackdropのopen状態をLoadingContextから取得
  return (
    <>
      <BrowserRouter basename={BASE_URL}>
        <Suspense fallback={<p>loading...</p>}>
          <LoadingProvider>
            <ErrorDialogProvider>
              <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/"
                        element={
                          <RequireAuth>
                            <AuthFrame />
                          </RequireAuth>
                        }
                      >
                        <Route path="/stock_list" element={<StockList />} />
                        <Route path="/packing_list" element={<PackingList />} />
                        <Route path="/packing" element={<Packing />} />
                        <Route path="/packing/:id" element={<Packing />} />
                        <Route path="/shipping_list" element={<ShippingList />} />
                        <Route path="/shipping" element={<Shipping />} />
                        <Route path="/shipping/:id" element={<Shipping />} />

                        {APP_MODE === 'subproc' && (
                          <>
                            <Route path="/proc_tube_list" element={<ProcListSubProc step="2.0" />} />
                            <Route path="/proc_tube" element={<ProcSubProc step="2.0" />} />
                            <Route path="/proc_tube/:id" element={<ProcSubProc step="2.0" />} />
                            <Route path="/proc_print_list" element={<ProcListSubProc step="1.0" />} />
                            <Route path="/proc_print" element={<ProcSubProc step="1.0" />} />
                            <Route path="/proc_print/:id" element={<ProcSubProc step="1.0" />} />
                            <Route path="/proc_bottom_list" element={<ProcListSubProc step="3.0" />} />
                            <Route path="/proc_bottom" element={<ProcSubProc step="3.0" />} />
                            <Route path="/proc_bottom/:id" element={<ProcSubProc step="3.0" />} />
                          </>
                        )}

                        {APP_MODE === 'factory' && (
                          <>
                            <Route path="/proc_print_list" element={<ProcList step="1.0" />} />
                            <Route path="/proc_tube_list" element={<ProcList step="2.0" />} />
                            <Route path="/proc_bottom_list" element={<ProcList step="3.0" />} />
                            <Route path="/proc_tube" element={<Proc step="2.0" />} />
                            <Route path="/proc_tube/:id" element={<Proc step="2.0" />} />
                            <Route path="/proc_print" element={<Proc step="1.0" />} />
                            <Route path="/proc_print/:id" element={<Proc step="1.0" />} />
                            <Route path="/proc_bottom" element={<Proc step="3.0" />} />
                            <Route path="/proc_bottom/:id" element={<Proc step="3.0" />} />
                            <Route path="/proc_inspection" element={<Proc step="20.0" />} />
                            <Route path="/proc_inspection/:id" element={<Proc step="20.0" />} />
                            <Route path="/proc_inspection_list" element={<ProcList step="20.0" />} />
                            <Route path="/reduce_material_print" element={<ReduceMaterial step="1.0" />} />
                            <Route path="/reduce_material_tube" element={<ReduceMaterial step="2.0" />} />
                            <Route path="/reduce_material_bottom" element={<ReduceMaterial step="3.0" />} />
                            <Route path="/reduce_material_print/:id" element={<ReduceMaterial step="1.0" />} />
                            <Route path="/reduce_material_tube/:id" element={<ReduceMaterial step="2.0" />} />
                            <Route path="/reduce_material_bottom/:id" element={<ReduceMaterial step="3.0" />} />
                            <Route path="/delivery_cost" element={<DeliveryCost />} />
                            <Route path="/stock_chart" element={<StockChart />} />
                            <Route path="/complaints" element={<Complaints />} />
                            <Route path="/pdftest" element={<PDFTest />} />
                            <Route path="/pdf_item/:item" element={<PDFItem />} />
                            <Route path="/test_agg" element={<TestAgg />} />
                            <Route
                              path="/test_rem/"
                              element={<ReduceMaterialList step="2.0" dateWork={new Date('2023-11-13')} draw={1} />}
                            />
                            <Route index element={<HomePage />} />
                          </>
                        )}
                        {APP_MODE === 'subproc' && <Route index element={<HomeSubProc />} />}
                      </Route>
                      {/* for test */}
                      <Route path="/test_dnd" element={<TestDnD />} />
                      <Route path="/*" element={<NotFound />} />
                      {/*<Route path="/*" element={<Navigate to="/login" replace />} />*/}
                    </Routes>
                  </ErrorBoundary>
                </LocalizationProvider>
              </AuthProvider>
            </ErrorDialogProvider>
          </LoadingProvider>
        </Suspense>
      </BrowserRouter>
    </>
  );
}
