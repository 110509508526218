import { axios, axiosThenRes, axiosCatchRes } from '../lib/axios';
import { AxiosResponse } from 'axios';

//  認証から戻るレスポンス型
/*
type UserResponse = {
  csrfToken: string;
  isAuth: string;
  user: AuthUser;
};
*/
//  ログイン済みチェック
export const authCheck = (): Promise<any> => {
  return axios
    .post('/auth_check.php')
    .then((res: AxiosResponse) => {
      return axiosThenRes(res);
    })
    .catch((err: any) => {
      return axiosCatchRes(err);
    });
};
//  ログイン処理関数
export const authLogin = (login_id: string, password: string): Promise<any> => {
  return axios
    .post('/auth_login.php', { login_id: login_id, password: password })
    .then((res: AxiosResponse) => {
      return axiosThenRes(res);
    })
    .catch((err: any) => {
      return axiosCatchRes(err);
    });
};
//  ログアウト処理関数
export const authLogout = (): Promise<any> => {
  return axios
    .post('/auth_logout.php')
    .then((res: AxiosResponse) => {
      return axiosThenRes(res);
    })
    .catch((err: any) => {
      return axiosCatchRes(err);
    });
};
