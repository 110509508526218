/**
 * FORMのSAVE/LOAD/DELETE処理をaxiosで投げる
 */
import { axios, axiosCatchRes, axiosThenRes } from '../lib/axios';

/**
 * 指定URLにasync/awaitでデータを投げてレスポンスを整形して戻す
 * @param url_send
 * @param data
 * @returns void
 */
export const sendFormData = async (url_send: string, data: any) => {
  return await axios.post(url_send, data, {}).then(axiosThenRes).catch(axiosCatchRes);
};
export const loadFormData = async (url_send: string, data: any) => {
  return await axios.post(url_send, data, {}).then(axiosThenRes).catch(axiosCatchRes);
};
export const loadData = async (url_send: string, data: any) => {
  return await axios.post(url_send, data, {}).then(axiosThenRes).catch(axiosCatchRes);
};
