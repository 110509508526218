/**
 * 汎用のエラーダイアログ設定
 */
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { createContext, ReactNode, useState, useCallback } from 'react';

//  Context作成
type TypeDialogContext = {
  errorMessage: string;
  setErrorMessage: (m: string) => void;
  openErrorDialog: boolean;
  setOpenErrorDialog: (b: boolean) => void;
  showErrorMessage: (m: string) => void;
};
export const ErrorDialogContext = createContext<TypeDialogContext>({
  errorMessage: '',
  setErrorMessage: (m: string) => undefined,
  openErrorDialog: false,
  setOpenErrorDialog: (b: boolean) => undefined,
  showErrorMessage: (m: string) => undefined,
});
//export const ErrorDialogContext = createContext({});

export const ErrorDialogProvider = ({ children }: { children: ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState<string>('aaa');
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const handleClose = () => {
    setOpenErrorDialog(false);
  };
  const showErrorMessage = useCallback((message: string) => {
    setErrorMessage(message);
    setOpenErrorDialog(true);
  }, []);
  //  Providerのvalue
  const value = { errorMessage, setErrorMessage, openErrorDialog, setOpenErrorDialog, showErrorMessage };

  return (
    <ErrorDialogContext.Provider value={value}>
      {children}
      <Dialog maxWidth="sm" fullWidth open={openErrorDialog} onClose={handleClose}>
        <DialogTitle>ERROR</DialogTitle>
        <DialogContent>{errorMessage}</DialogContent>
      </Dialog>
    </ErrorDialogContext.Provider>
  );
};
