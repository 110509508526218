import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchItemPDF from '../../modules/SearchItemPDF';
import { APP_TITLE } from '../../config';

//import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import LogoIcon from '../../elements/LogoIcon';
import { Stack } from '@mui/material';

//  ページリンク設定
const pages = [
  { key: 'proc_print_list', link: '/proc_print_list', label: '印刷', admin: '41' },
  { key: 'proc_tube_list', link: '/proc_tube_list', label: '製筒', admin: '42' },
  { key: 'proc_bottom_list', link: '/proc_bottom_list', label: '底工程', admin: '43' },
  { key: 'proc_inspection_list', link: '/packing_list', label: '完成品', admin: '420' },
  { key: 'delivery_cost', link: '/shipping_list', label: '出荷', admin: '1' },
  { key: 'stock_chart', link: '/stock_list', label: '在庫', admin: '1' },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { isAuth, user, fnLogout } = React.useContext(AuthContext);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    console.log('handleCloseUserMenu');
    setAnchorElUser(null);
  };

  //  ログアウト処理
  const handleLogout = () => {
    //console.log('ログアウトハンドラ・・・useContextのを直接使っても良さそう');
    fnLogout(() => {});
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <LogoIcon sx={{ mr: 2, color: 'white' }} component={Link} to="/" />
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{ color: 'white', textDecoration: 'none', mr: 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}
          >
            {APP_TITLE}
          </Typography>
          {/* mobile(xs)のメニュー表示 */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map(
                (page) =>
                  (user.flag_admin === '1' || page.admin !== '1') && (
                    <MenuItem key={page.key} onClick={handleCloseNavMenu} component={Link} to={page.link}>
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>

          {/* PC(md)の表示 */}
          <Box
            sx={{
              '> .active': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
              flexGrow: 1,
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {pages.map(
              (page) =>
                (user.flag_admin === '1' || page.admin !== '1') && (
                  <NavLink
                    key={page.key}
                    to={page.link}
                    style={{ textDecoration: 'none' }}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
                      {page.label}
                    </Button>
                  </NavLink>
                )
            )}
          </Box>

          {/* 右上ログインAvatar */}
          <Stack direction="row" sx={{ flexGrow: 0 }}>
            <Tooltip title="Logout">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={isAuth ? user.auth_name : ''}>
                  <LogoutIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="logout" onClick={handleLogout}>
                <Typography textAlign="center">ログアウト</Typography>
              </MenuItem>
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
