/**
 * MODE=subproc用のproc入力
 */

import { ReactChild, useContext, useEffect, useState } from 'react';
import { Typography, Container, Box, Grid, Button, Divider, Chip, Stack } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { InputDate, InputNumber, InputText, InputSelect } from '../elements/InputsHookForm';
import FixedBottomNavi from '../elements/FixedBottomNavi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yup, yupNum, yupNumReq, yupStrReq, yupDate, yupDateReq } from '../lib/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '../providers/AuthProvider';
import { LoadingContext } from '../providers/LoadingProvider';
import { ErrorDialogContext } from '../providers/ErrorDialogProvider';
import { loadFormData, sendFormData } from '../apis/form';
import * as dateFns from 'date-fns';
// アイコン
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ListIcon from '@mui/icons-material/List';
import { ButtonNew } from '../elements/Buttons';
import { SnackbarInfo } from '../modules/SnackbarInfo';
import { dictStepSubProc as dictStep, dictProcURL, dictProcListURL } from '../utils/dicts';
import { getMachines } from '../utils/funcs';

//  数値入力GridItem + InputNumber
const GI = ({ control, label, name, ...rest }: any) => (
  <Grid item xs={6} sm={3} md={2}>
    <InputNumber control={control} label={label} name={name} {...rest} />
  </Grid>
);

//  底針日報登録項目のTYPE
type TypeProc = {
  date_work: Date;
  item: string;
  mst_machine: string;
  qty_in: number;
  qty_ok: number;
  qty_ng1: number;
  qty_ng2: number;
  qty_ng4: number;
  qty_rest: number;
  worker: string;
  num_workers: number | undefined;
  memo: string;
};
// Resolver用のschema定義
const schema = yup.object().shape({
  item: yupStrReq,
  date_work: yupDateReq,
  mst_machine: yupNumReq,
  qty_in: yupNum,
  time_start: yupDate,
  time_end: yupDate,
});

const ProcDivider = ({ children }: { children: ReactChild }) => (
  <Divider textAlign="left" sx={{ my: 2 }}>
    {children}
  </Divider>
);

//  stepフラグによる

//  デバッグフラグ
const debug = false;

//  コンポーネント!!! - - - - - - - - - - - - - - -
export default function App({ step }: { step: string }) {
  //  useFormでフォームの設定
  const { control, handleSubmit, setValue, getValues, reset } = useForm<TypeProc>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: { date_work: new Date(), item: '' },
  });

  //  BottomNaviで一覧に移動する際に使うnavigate
  const navigate = useNavigate();

  //  routerのuseParamsでproc.idを取得
  const params = useParams();
  //  routerのuseSearchParams
  const [searchParams] = useSearchParams();

  //  LoadingContext
  const { setIsLoading } = useContext(LoadingContext);
  //  ErrorDialogContextからshowErrorMessageを取得
  const { showErrorMessage } = useContext(ErrorDialogContext);

  //  AuthContextから取得した作業機械の配列
  //const [machines, setMachines] = useState([]);
  const { masters } = useContext(AuthContext);
  const machines = getMachines(masters.machines, step);

  //  snackbarのuseState
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (params.id) {
      //  更新モード、データローディング処理
      setIsLoading(true);
      (async () => {
        const res = await loadFormData('load_proc.php', { id: params.id, step: step });
        setIsLoading(false);
        if (res.success) {
          res.data.data.date_work = dateFns.parse(res.data.data.date_work, 'yyyy-MM-dd', new Date());
          //  reset()メソッドで初期データをセット
          reset(res.data.data);
        } else {
          //  ローディングエラー表示
          showErrorMessage('データの呼び出しに失敗しました<br>' + res.msg);
        }
      })();
    } else {
      //  新規登録モード、初期化処理
      setIsLoading(true);
      setTimeout(() => {
        const values = getValues();
        reset({
          date_work: values.date_work,
          mst_machine: values.mst_machine,
        });

        let machineID: string | null = '';
        machineID = searchParams.get('m');
        const dateWorkStr = searchParams.get('d');

        if (dateWorkStr) {
          const dateWork = dateFns.parse(dateWorkStr, 'yyyyMMdd', new Date());
          setValue('date_work', dateWork);
        }

        if (machineID) {
          setValue('mst_machine', machineID);
        }

        setIsLoading(false);
      }, 500);
    }
  }, [params.id, searchParams, step, getValues, reset, setIsLoading, setValue, showErrorMessage]);

  //  submit処理
  const onSubmit: SubmitHandler<TypeProc> = (data) => {
    setIsLoading(true);
    //  一度anyでformDataに入れてから一部フォーマット変換
    const formData = { ...data } as any;

    //  日付と時間をフォーマット

    formData.date_work = data.date_work ? dateFns.format(data.date_work, 'yyyy-MM-dd') : null;
    //  step追加
    formData.step = step;

    //  asyncでデータ送信処理
    (async () => {
      const res = await sendFormData('save_proc.php', formData);
      setIsLoading(false);
      if (res.success) {
        setOpenSnackbar(true);
        navigate(
          dictProcListURL[step] +
            '/?d=' +
            (data.date_work ? dateFns.format(data.date_work, 'yyyyMMdd') : dateFns.format(new Date(), 'yyyyMMdd'))
        );
      } else {
        //  サーバ処理エラー
        if (res.message) {
          showErrorMessage(res.message);
        } else {
          showErrorMessage('日報登録処理に失敗しました');
        }
      }
    })();
  };
  //  submitエラー処理
  const onSubmitError = () => {
    console.log('handleSubmit Error');
  };

  // 残数の自動計算
  const calcQtyRest = () => {
    const values = getValues();
    setValue(
      'qty_ng4',
      Number(values.qty_in) -
        Number(values.qty_ok) -
        Number(values.qty_ng1) -
        Number(values.qty_ng2) -
        Number(values.qty_rest)
    );
  };

  return (
    <>
      <Container sx={{ height: '100%' }}>
        <Stack direction="row" sx={{ my: 2 }} spacing={1}>
          <Typography variant="h5" component="h2">
            {dictStep[step]}日報
          </Typography>
          {params.id ? (
            <Chip color="info" label="修正" size="small" />
          ) : (
            <Chip color="error" label="新規" size="small" />
          )}
        </Stack>

        <Box>
          {debug && (
            <>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  console.log(getValues());
                }}
              >
                Check Data
              </Button>
            </>
          )}
        </Box>
        <ProcDivider>基本 *</ProcDivider>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <InputDate control={control} label="作業日" name="date_work" fullWidth required />
          </Grid>
          <Grid item xs={6} md={6}>
            <InputSelect
              control={control}
              label="機械"
              name="mst_machine"
              items={machines}
              onChange={(e: any) => {
                // to-do?
              }}
              fullWidth
              required
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <InputSelect
              control={control}
              items={masters.items}
              onChange={(e: any, option: any) => {
                //console.log('onChangeCustommmm');
                //console.log(option);
              }}
              label="商品"
              name="item"
              required
              fullWidth
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <InputText control={control} label="備考" name="memo" fullWidth />
          </Grid>
        </Grid>
        <ProcDivider>作業数量</ProcDivider>
        <Grid container spacing={2}>
          {step !== '1.0' && <GI control={control} label="投入数" name="qty_in" required onChange={calcQtyRest} />}
          <GI control={control} label="良品数" name="qty_ok" required onChange={calcQtyRest} />
          <GI control={control} label="不良数" name="qty_ng1" required onChange={calcQtyRest} />
          {step !== '1.0' && (
            <>
              <GI control={control} label="前工程不良" name="qty_ng2" required onChange={calcQtyRest} />
              <GI control={control} label="残数" name="qty_rest" onChange={calcQtyRest} />
              <GI control={control} label="遺失数" name="qty_ng4" variant="filled" InputProps={{ readOnly: true }} />
            </>
          )}
        </Grid>

        <Box sx={{ height: 100 }}></Box>
      </Container>
      <FixedBottomNavi>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{ m: 1 }}
            startIcon={<ListIcon />}
            onClick={() => {
              //  作業日と機械をキープしてListに
              const mstMachine = getValues('mst_machine');
              const dateWork = getValues('date_work');
              let path = dictProcListURL[step] + '?d=' + dateFns.format(dateWork, 'yyyyMMdd');
              if (mstMachine) {
                path += '&m=' + mstMachine;
              }
              navigate(path);
            }}
          >
            一覧
          </Button>
          {params.id && (
            <ButtonNew
              variant="contained"
              color="error"
              sx={{ m: 1 }}
              onClick={() => {
                const dateWork = getValues('date_work');
                const machineID = getValues('mst_machine');
                if (machineID) {
                  navigate(dictProcURL[step] + '?d=' + dateFns.format(dateWork, 'yyyyMMdd') + '&m=' + machineID);
                } else {
                  navigate(dictProcURL[step] + '?d=' + dateFns.format(dateWork, 'yyyyMMdd'));
                }
                //reset
                //navigate(0);
              }}
            >
              新規モード
            </ButtonNew>
          )}

          <Button
            variant="contained"
            color="warning"
            size="large"
            sx={{ m: 1 }}
            startIcon={<CancelIcon />}
            onClick={() => {
              const values = getValues();
              reset({
                mst_machine: values.mst_machine,
                date_work: values.date_work,
                worker: values.worker,
              });
            }}
          >
            リセット
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ m: 1 }}
            startIcon={<SaveIcon />}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          >
            保存
          </Button>
        </Box>
      </FixedBottomNavi>
      <SnackbarInfo
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        日報登録が完了しました
      </SnackbarInfo>
    </>
  );
}
