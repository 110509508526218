/**
 * リモートサーバのURLにパラメータを送ってエクセルをダウンロードするボタン
 */
import { useContext } from 'react';
import { Button } from '@mui/material';
//import { FaRegFileExcel } from 'react-icons/fa';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { gridMakeExcel } from '../apis/grid';
import { saveAs } from 'file-saver';
import { LoadingContext } from '../providers/LoadingProvider';

type TypeProps = {
  filename: string;
  url_excel: string;
  params: any;
  buttonText?: string;
  size?: 'medium' | 'large' | 'small' | undefined;
};
const ButtonRemoteExcel = ({ url_excel, params, filename, buttonText = 'EXCEL出力', size = 'medium' }: TypeProps) => {
  const { setIsLoading } = useContext(LoadingContext);
  const getExcel = async () => {
    setIsLoading(true);

    /* 
    //  xhr ベタ書き
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://yps.vacatono.net/yps/rphp/' + url_excel, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.responseType = 'blob';
    xhr.onload = function (e) {
      if (this.status === 200) {
        const blob = this.response;
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        document.body.appendChild(a);
        //a.style = 'display: none';
        a.href = blobUrl;
        a.download = filename;
        a.click();
      }
      setIsLoading(false);
    };
    xhr.send(JSON.stringify(params));
    */
    const res = await gridMakeExcel(url_excel, params);
    //console.log(res);

    if (res.success) {
      const blob = new Blob([res.data]);
      saveAs(blob, filename);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Button variant="outlined" color="primary" size={size} onClick={getExcel}>
        <SaveAltIcon fontSize={size} sx={{ ml: '-2px', mr: 1, my: '2px' }} />
        {buttonText}
      </Button>
    </>
  );
};
export default ButtonRemoteExcel;
