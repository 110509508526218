import { createTheme } from '@mui/material/styles';
import { jaJP } from '@mui/material/locale';

export default createTheme(
  {
    palette: {
      primary: {
        main: '#256921',
      },
      secondary: {
        main: '#1565c0',
      },
      background: {
        default: '#f6f6f6',
      },
    },
  },
  jaJP
);
