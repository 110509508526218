import { axios, axiosCatchRes, axiosThenRes, axiosThenBlobRes } from '../lib/axios';
import { AxiosResponse } from 'axios';
import { GridSortModel, GridFilterModel } from '@mui/x-data-grid';

//  全件取得用ローダー
export const gridAllDataLoader = ({ url_grid, params = {} }: any) => {
  return axios.post(url_grid, params, {});
};

//  フルリモート用ローダー
export const gridDataLoader = async (
  url_grid: string,
  page: number,
  pageSize: number,
  sortModel?: GridSortModel,
  filterModel?: GridFilterModel | undefined,
  extraParams?: {}
) => {
  //  awaitでaxiosでリモートからデータ呼び出して戻す
  return await axios
    .post(
      url_grid,
      {
        page: page,
        pageSize: pageSize,
        sortModel: sortModel,
        filterModel: filterModel,
        extraParams: extraParams,
      },
      {}
    )
    .then((res: AxiosResponse) => {
      return axiosThenRes(res);
    })
    .catch((err: any) => {
      return axiosCatchRes(err);
    });
};

/**
 *  DataGridからparams送ってExcelバイナリを戻す
 *
 */
export const gridMakeExcel = async (url_excel: string, params: {}) => {
  //return await axios.post(url_excel, { params: params }).then(axiosThenBlobRes).catch(axiosCatchRes);
  return (
    axios
      //.post(url_excel, { params: params }, { headers: { responseType: 'blob', dataType: 'binary' } })
      .post(url_excel, { params: params }, { responseType: 'blob' })
      .then(axiosThenBlobRes)
      .catch(axiosCatchRes)
  );
};

/**
 * DataGridのcelleditを送信する
 * @param url_send
 * @param data
 * @returns
 */
/*
export const sendCellEdit = async (url_send: string, data: any) => {
  return await axios.post(url_send, data, {}).then(axiosThenRes).catch(axiosCatchRes);
};
*/
export const sendCellEdit = (url_send: string, data: any) => {
  return axios.post(url_send, data, {}).then(axiosThenRes).catch(axiosCatchRes);
};

/**
 * GridのActions辺りからDialogを出してリモートに送信するケース（削除とか）
 */
export const sendDialog = (url_send: string, data: any) => {
  return axios.post(url_send, data, {}).then(axiosThenRes).catch(axiosCatchRes);
};
