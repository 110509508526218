import { SvgIcon } from '@mui/material';
import SvgYngswLogo from '../svg/YngswLogo';

const LogoIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <SvgYngswLogo />
    </SvgIcon>
  );
};
export default LogoIcon;
