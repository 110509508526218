import { Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ReactChild, ReactNode } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import CalculateIcon from '@mui/icons-material/Calculate';
import CancelIcon from '@mui/icons-material/Cancel';

/* ローディングボタン */
type TypeButtonLoadingProps = {
  loading?: boolean;
  children: ReactChild;
  onClick: (e: any) => void;
  size?: 'medium' | 'large' | 'small' | undefined;
  [key: string]: any;
};
export const ButtonLoading = ({
  children,
  onClick,
  loading = false,
  size = 'medium',
  ...rest
}: TypeButtonLoadingProps) => {
  return (
    <LoadingButton size={size} loading={loading} onClick={onClick} {...rest} variant="outlined">
      <RefreshIcon fontSize={size} sx={{ ml: '-2px', mr: 1, my: '2px' }} />
      {children}
    </LoadingButton>
  );
};

export const ButtonCancel = ({
  children,
  size = 'small',
  ...rest
}: {
  children: ReactNode;
  size?: 'medium' | 'large' | 'small' | undefined;
  [key: string]: any;
}) => {
  return (
    <Button variant="outlined" color="success" size={size} {...rest}>
      <CancelIcon fontSize={size} sx={{ ml: '-2px', mr: 1, my: '2px' }} />
      {children}
    </Button>
  );
};

/* 削除ボタン */
type TypeButtonDeleteProps = {
  children: ReactChild;
  size?: 'medium' | 'large' | 'small' | undefined;
  [key: string]: any;
};
export const ButtonDelete = ({ children, size = 'small', ...rest }: TypeButtonDeleteProps) => {
  return (
    <>
      <Button variant="outlined" size={size} color="warning" {...rest}>
        <DeleteIcon fontSize={size} sx={{ ml: '-2px', mr: 1, my: '2px' }} />
        {children}
      </Button>
    </>
  );
};
type TypeButtonIconProps = {
  onClick: () => void;
  size?: 'medium' | 'large' | 'small' | undefined;
  [key: string]: any; //  ...rest用
};
/* 削除アイコンボタン(DataGrid actions用) */
export const ButtonIconDelete = ({ onClick, size = 'small', ...rest }: TypeButtonIconProps) => {
  return (
    <IconButton aria-label="delete" size={size} onClick={onClick} {...rest}>
      <DeleteIcon fontSize={size} />
    </IconButton>
  );
};

/* 編集アイコンボタン(DataGrid Actions用) */
export const ButtonIconEdit = ({ onClick, size = 'small', ...rest }: TypeButtonIconProps) => {
  return (
    <IconButton aria-label="edit" size={size} onClick={onClick} {...rest}>
      <EditIcon fontSize={size} />
    </IconButton>
  );
};

/* 計算機ボタン */
export const ButtonIconCalculate = ({ onClick, size = 'small', ...rest }: TypeButtonIconProps) => {
  return (
    <IconButton aria-label="edit" size={size} onClick={onClick} {...rest}>
      <CalculateIcon fontSize={size} />
    </IconButton>
  );
};

/* 新規登録ボタン */
type TypeButtonNewProps = {
  children: ReactChild;
  size?: 'medium' | 'large' | 'small' | undefined;
  [key: string]: any; //  ...rest用
};
export const ButtonNew = ({ children, size = 'medium', ...rest }: TypeButtonNewProps) => {
  return (
    <Button color="primary" size={size} {...rest}>
      <AddCircleOutlineIcon fontSize={size} sx={{ ml: '-2px', mr: 1, my: '2px' }} />
      {children}
    </Button>
  );
};

/* 保存ボタン */
type TypeButtonSave = {
  children: ReactChild;
  onClick: () => void;
  size?: 'medium' | 'large' | 'small' | undefined;
};
export const ButtonSave = ({ children, onClick, size = 'small', ...rest }: TypeButtonSave) => (
  <Button size={size} onClick={onClick} variant="contained" color="primary" {...rest}>
    <SaveIcon fontSize={size} sx={{ ml: '-2px', mr: 1 }} />
    {children}
  </Button>
);

/* 編集ボタン */
type TypeButtonEditProps = {
  children: ReactChild;
  onClick: () => void;
  size?: 'medium' | 'large' | 'small' | undefined;
};
export const ButtonEdit = ({ children, onClick, size = 'small', ...rest }: TypeButtonEditProps) => {
  return (
    <Button color="primary" size={size} variant="outlined" onClick={onClick} {...rest}>
      <EditIcon fontSize={size} sx={{ ml: '-2px', mr: 1 }} />
      {children}
    </Button>
  );
};

/* メニューボタン */
type TypeButtonMenuProps = {
  children: ReactChild;
  onClick: () => void;
  size?: 'medium' | 'large' | 'small' | undefined;
};
export const ButtonMenu = ({ children, size, ...rest }: TypeButtonMenuProps) => {
  return (
    <Button size={size} {...rest}>
      <MenuIcon sx={{ ml: '-2px', mr: 1 }} />
      {children}
    </Button>
  );
};
