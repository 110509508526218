/**
 * yupの日本語化とかaddMethodとかvalidationのパターン化とか
 */

import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: '入力エラーです',
    required: '必須入力項目です',
    oneOf: ({ values }) => `次の値のいずれかでなければなりません: ${values}`,
    notOneOf: ({ values }) => `次の値のいずれかであってはなりません: ${values}`,
    notType: '形式が違います',
  },
  string: {
    length: ({ length }) => `${length}文字入力して下さい`,
    min: ({ min }) => `${min}文字以上入力してください`,
    max: ({ max }) => `${max}文字以内で入力して下さい`,
    matches: '形式が違います',
    email: '形式が違います',
    url: '形式が違います',
    trim: '前後にスペースを入れてはいけません',
    lowercase: '小文字でなければなりません',
    uppercase: '大文字でなければなりません',
  },
  number: {
    min: ({ min }) => `${min}以上の値を入力して下さい`,
    max: ({ max }) => `${max}以下の値を入力して下さい`,
    //lessThan: ({ lessThan }) => `${lessThan}より小さい値を入力して下さい`,
    //moreThan: ({ moreThan }) => `${moreThan}より大きい値を入力して下さい`,
    //notEqual: ({ notEqual }) => `${notEqual}と違う値を入力して下さい`,
    positive: '正の数を入力して下さい',
    negative: '負の数を入力して下さい',
    integer: '整数を入力して下さい',
  },
  date: {
    //default: '形式が違います',
    min: ({ min }) => `${min}以上の日付を入力して下さい`,
    max: ({ max }) => `${max}以下の日付を入力して下さい`,
  },
  object: {
    noUnknown: '有効なキーを持ったデータを入力して下さい',
  },
  array: {
    min: ({ min }) => `${min}個以上の値を入力して下さい`,
    max: ({ max }) => `${max}個以下の値を入力して下さい`,
  },
});

// カタカナチェックメソッド
yup.addMethod(yup.string, 'kana', function () {
  return this.test('kana', 'カタカナで入力してください', function (value: any) {
    if (value == null || value === '') return true;
    return value.match(/^[ァ-ヶー　 ]+$/);
  });
});

// yup.StringSchemaのカスタムメソッド用の拡張
declare module 'yup' {
  interface StringSchema {
    kana(this: StringSchema): StringSchema;
  }
}

//  0のみ除外
/* yup.addMethodする場合、declare module 'yup' でinterfaceを再設定する必要があるので、
  直接test記述したschemaをexportする
yup.addMethod<yup.NumberSchema>(yup.number, 'err0', function () {
  return this.test('err0', '0以外の数値を入力してください', function (value: any) {
    if (value == null || value === '') return true;
    return Number(value) !== 0;
  });
});
//yup.addMethod(yup.NumberSchema, 'err0');
*/

//  null許容のnumberパターン
const transNull = (val: any, orgVal: any) => (String(orgVal).trim() === '' ? null : val);
const yupNumReq = yup.number().nullable().required().transform(transNull);
const yupNum = yup.number().nullable().transform(transNull);

const yupNumNoZeroReq = yup
  .number()
  .nullable()
  .required()
  .test('noZero', '0以外の数値を入力してください', (value: any) => {
    if (value == null || value === '') return true;
    return Number(value) !== 0;
  })
  .transform(transNull);

/* MySQLで0000-00-00がorgValに入るケースを必須エラーにする */
const yupDateReq = yup
  .date()
  .nullable()
  .required()
  .transform((val: any, orgVal: any) => {
    return String(orgVal).trim() === '' || String(orgVal) === '0000-00-00' ? null : val;
  });

const yupDate = yup
  .date()
  .nullable()
  .transform((val: any, orgVal: any) => {
    return String(orgVal).trim() === '' || String(orgVal) === '0000-00-00' ? null : val;
  });
//const yupDateReq = yup.date().nullable().required();
const yupStrReq = yup.string().required();

const yupStr = yup.string();
/*const yupStrKana = yup
  .string()
  .required()
  .test('kana', 'カタカナで入力してください', (value: any) => {
    if (value == null || value === '') return true;
    return value.match(/^[ァ-ヶー　 ]+$/);
  });
  */
const yupStrKana = yup.string().kana();

export { yup, transNull, yupNumReq, yupNum, yupNumNoZeroReq, yupDate, yupDateReq, yupStrReq, yupStr, yupStrKana };
