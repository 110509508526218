import { useEffect, useRef } from 'react';

import { DatePicker } from '@mui/x-date-pickers';
//import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import { InputAdornment, MenuItem, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
//import { MobileDateRangePicker } from '@mui/lab';

/** 外側からvalue/setValue渡すてすっと */
export const TextFiedld = ({ value, setValue, ...rest }: any) => {
  return (
    <TextField
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      {...rest}
    />
  );
};
/**
 * 検索フォーム
 *
 */
export const SearchField = ({ value, setValue, onClear = () => null, ...rest }: any) => {
  //  clearボタンでの処理を追加するためinput[type=search]のDOMに直接addEventListenerする。
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('search', onClear);
    }
  }, [onClear]);

  return (
    <TextField
      type="search"
      size="small"
      label="search"
      inputRef={ref}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      sx={{ width: 160, mr: 1 }}
      {...rest}
    />
  );
};
/**
 * シンプルDatepicker
 * @param props
 * @returns
 */
export const DateField = ({ value, setValue, ...rest }: any) => {
  return (
    <DatePicker
      {...rest}
      value={value}
      size="small"
      //mask="____/__/__"
      onChange={(newValue: any) => {
        setValue(newValue);
      }}
      renderInput={(params: TextFieldProps) => (
        <TextField size="small" InputLabelProps={{ shrink: true }} sx={{ width: 150, mr: 1 }} {...params} />
      )}
    />
  );
};

export const SelectField = ({ value, setValue, label, items = [], ...rest }: any) => {
  return (
    <TextField
      label={label}
      size="small"
      select
      value={value}
      onChange={(e: any) => {
        setValue(e.target.value);
      }}
      {...rest}
    >
      {items.map((v: any) => (
        <MenuItem key={v.id as string} value={v.id as string}>
          {v.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
