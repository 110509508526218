import { ReactNode, useContext } from 'react';
import { Grid, Button, Paper, Alert, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const GridItem = ({ children, title, link }: { children: ReactNode; title: string; link?: string }) => {
  return (
    <Grid item sm={6} xs={12}>
      <Paper sx={{ p: 2, m: 1, minHeight: '15em' }} elevation={3}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Box>{children}</Box>
      </Paper>
    </Grid>
  );
};

/* メイン */
const HomePage = () => {
  return (
    <>
      <Grid container sx={{ mt: 4 }}>
        <GridItem title="印刷日報登録" link="proc_bottom">
          印刷日報登録処理
          <br />
          日付指定の印刷日報一覧と新規登録/登録済み編集
          <br />
          <Button variant="contained" component={Link} to="/proc_print" sx={{ m: 1 }}>
            印刷日報登録
          </Button>
          <Button variant="contained" component={Link} to="/proc_print_list" sx={{ m: 1 }}>
            印刷日報一覧
          </Button>
        </GridItem>
        <GridItem title="製筒日報登録" link="proc_bottom">
          製筒日報登録処理
          <br />
          日付指定の製筒日報一覧と登録済み編集
          <br />
          <Button variant="contained" component={Link} to="/proc_tube" sx={{ m: 1 }}>
            製筒日報登録
          </Button>
          <Button variant="contained" component={Link} to="/proc_tube_list" sx={{ m: 1 }}>
            製筒日報一覧
          </Button>
        </GridItem>
        <GridItem title="底工程日報登録" link="proc_bottom">
          底工程日報登録処理、底工程不良詳細も入力。
          <br />
          日付指定の日報一覧と新規登録/登録済み編集
          <br />
          <Button variant="contained" component={Link} to="/proc_bottom" sx={{ m: 1 }}>
            底工程日報登録
          </Button>
          <Button variant="contained" component={Link} to="/proc_bottom_list" sx={{ m: 1 }}>
            底工程日報一覧
          </Button>
        </GridItem>
        <GridItem title="完成品日報登録">
          完成品日報の登録処理。
          <br />
          <Button variant="contained" component={Link} to="/packing" sx={{ m: 1 }}>
            完成品日報登録
          </Button>
          <Button variant="contained" component={Link} to="/packing_list" sx={{ m: 1 }}>
            完成品日報一覧
          </Button>
        </GridItem>
        <GridItem title="出荷処理登録">
          出荷の登録処理。
          <br />
          <Button variant="contained" component={Link} to="/shipping" sx={{ m: 1 }}>
            出荷処理
          </Button>
          <Button variant="contained" component={Link} to="/shipping_list" sx={{ m: 1 }}>
            出荷処理一覧
          </Button>
        </GridItem>

        <Grid item sm={6} xs={12}>
          <Paper sx={{ p: 2, m: 1, minHeight: '15em' }} elevation={3}>
            <Typography variant="h6" gutterBottom>
              在庫一覧
            </Typography>
            <Box>製品/印刷/製筒/底工程在庫の一覧</Box>

            <Button variant="contained" component={Link} to="/stock_list" sx={{ m: 1 }}>
              在庫一覧
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default HomePage;
