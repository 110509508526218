/**
 * 完成品一覧
 * routes.tsxでstepを渡して複数工程で共通利用
 */

import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from '@mui/material';
//import { useSearchParams, useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowClassNameParams,
  GridRowModel,
  GridRowParams,
  GridToolbarContainer,
} from '@mui/x-data-grid';

import { colNum, colDate } from '../utils/gridColDefs';
import { ButtonLoading, ButtonNew, ButtonCancel, ButtonDelete } from '../elements/Buttons';
import { ErrorDialogContext } from '../providers/ErrorDialogProvider';

import { gridAllDataLoader, sendDialog } from '../apis/grid';
//import { DateField } from '../elements/InputsControlled';
import * as dateFns from 'date-fns';
//import { AuthContext } from '../providers/AuthProvider';
import { sleep } from '../utils/funcs';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarInfo } from '../modules/SnackbarInfo';

//  コンポーネント!!! - - - - - - - - - - - - - - -
export default function App() {
  //  パス指定の変数取得
  //const params = useParams();
  //  useParamsをuseSearchParamsに切替
  //const [searchParams] = useSearchParams();

  //const dateWorkStr = searchParams.get('d');
  //console.log(params);
  //  contextからmasters取得
  //const { masters, user } = useContext(AuthContext);

  const navigate = useNavigate();

  /*
  const [dateWork, setDateWork] = useState<Date | null>(
    dateWorkStr ? dateFns.parse(dateWorkStr as string, 'yyyyMMdd', new Date()) : new Date()
  );
  */

  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  //  エラーDialogのopen
  //const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  //  削除対象のIDとopenフラグと商品名
  const [deleteStates, setDeleteStates] = useState({ open: false, id: 0, item: '', item_name: '' });
  const closeDeleteDialog = () => {
    setDeleteStates({ open: false, id: 0, item: '', item_name: '' });
  };
  const { showErrorMessage } = useContext(ErrorDialogContext);

  const [openSnack, setOpenSnack] = useState(false);
  const setCloseSnack = () => {
    setOpenSnack(false);
  };

  //  データローディング関数を定義
  const fnLoadDataGrid = useCallback(async () => {
    await setLoading(true);
    const res = await gridAllDataLoader({
      url_grid: 'grid_packing.php',
      //params: { date: dateFns.format(dateWork as Date, 'yyyy-MM-dd') },
    });
    setRows(res.data.data);
    setLoading(false);
  }, []);

  //  初期データ読み込み
  useEffect(() => {
    fnLoadDataGrid();
  }, [fnLoadDataGrid]);

  //  カラム定義
  const columns: GridColDef[] = [
    {
      headerName: '編集',
      field: 'actions',
      type: 'actions',
      sortable: false,
      width: 100,
      getActions: (params: GridRowParams) => {
        let disableActionRow = false;
        if (params.row.id === 'sum') {
          disableActionRow = true;
        }
        return [
          <GridActionsCellItem
            label=""
            color="info"
            icon={<EditIcon />}
            onClick={() => {
              //navigate('/proc_bottom/' + params.id);
              navigate('/packing/' + params.id);
            }}
            onResize={() => null}
            onResizeCapture={() => null}
            showInMenu={undefined}
            disabled={disableActionRow}
          />,
          <GridActionsCellItem
            label="削除"
            color="warning"
            icon={<DeleteIcon />}
            onClick={() => {
              setDeleteStates({
                open: true,
                item: params.row.item,
                item_name: params.row.item_name,
                id: params.row.id,
              });
            }}
            onResize={() => null}
            onResizeCapture={() => null}
            showInMenu={undefined}
          />,
        ];
      },
    } as GridColDef,
    colDate({ field: 'date_work', headerName: '作業日', width: 120 }),
    { field: 'item', headerName: '品番', width: 90, sortable: false },
    { field: 'item_name', headerName: '品名', width: 200, sortable: false },
    colNum({ field: 'qty', headerName: '完成品数', width: 70, sortable: false }),
    { field: 'memo', headerName: '備考', width: 100, sortable: false },
  ];

  //  DataGridのToolbarコンポーネント
  const CustomToolbar = useCallback(() => {
    return (
      <>
        <GridToolbarContainer>
          <ButtonLoading onClick={fnLoadDataGrid} variant="outlined" sx={{ m: 1 }}>
            再読込
          </ButtonLoading>

          <ButtonNew
            variant="contained"
            color="error"
            sx={{ m: 1 }}
            onClick={() => {
              const dateWorkStr = dateFns.format(new Date(), 'yyyyMMdd');
              navigate('/packing/?d=' + dateWorkStr);
            }}
          >
            新規登録
          </ButtonNew>
        </GridToolbarContainer>
      </>
    );
  }, [fnLoadDataGrid, navigate]);

  return (
    <>
      <Box
        sx={{
          height: `calc(100vh - 100px)`,
          display: 'flex',
          flexFlow: 'column',
          '& ._row_summary': { bgcolor: () => '#fff9e9' },
        }}
      >
        <Typography variant="h5" component="h2" sx={{ my: 2 }}>
          完成品一覧
        </Typography>
        <DataGrid
          rows={rows}
          loading={loading}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          hideFooter={true}
          getRowClassName={(params: GridRowClassNameParams) => {
            if (params.row.id === 'sum') {
              return '_row_summary';
            } else {
              return '';
            }
          }}
        />
      </Box>
      <Dialog open={deleteStates.open} maxWidth="sm" fullWidth onClose={closeDeleteDialog}>
        <DialogTitle>日報削除</DialogTitle>
        <DialogContent>
          <DialogContentText>以下の日報を削除します。よろしいですか？</DialogContentText>
          <Alert severity="warning" sx={{ mx: 0, my: 1 }}>
            {deleteStates.item}:{deleteStates.item_name}
          </Alert>
        </DialogContent>
        <DialogActions>
          <ButtonCancel onClick={closeDeleteDialog}>キャンセル</ButtonCancel>
          <ButtonDelete
            onClick={async () => {
              await setLoading(true);
              await closeDeleteDialog();
              const res = await sendDialog('delete_packing.php', { id: deleteStates.id });
              console.log(res);

              await sleep(300);
              await setLoading(false);
              if (res.success) {
                await fnLoadDataGrid();
                await setOpenSnack(true);
              } else {
                if (res.message) {
                  showErrorMessage(res.message);
                } else {
                  showErrorMessage('削除処理に失敗しました');
                }
              }
            }}
          >
            削除実行
          </ButtonDelete>
        </DialogActions>
      </Dialog>
      <SnackbarInfo open={openSnack} onClose={setCloseSnack}>
        日報の削除処理が完了しました。
      </SnackbarInfo>
    </>
  );
}
