/**
 * 底貼の日報設定
 */

import { ReactChild, useCallback, useContext, useEffect, useState } from 'react';
import { Typography, Container, Box, Grid, Button, Divider, TextField, Chip, Stack } from '@mui/material';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import {
  InputDate,
  InputTime,
  InputNumber,
  InputText,
  InputSelect,
  InputAutocompleteRemote,
} from '../elements/InputsHookForm';
import FixedBottomNavi from '../elements/FixedBottomNavi';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yup, yupNum, yupNumReq, yupStrReq, yupDate, yupDateReq } from '../lib/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '../providers/AuthProvider';
import { LoadingContext } from '../providers/LoadingProvider';
import { ErrorDialogContext } from '../providers/ErrorDialogProvider';
import { loadFormData, sendFormData, loadData } from '../apis/form';
import * as dateFns from 'date-fns';
// アイコン
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ListIcon from '@mui/icons-material/List';
import { ButtonNew } from '../elements/Buttons';
import { SnackbarInfo } from '../modules/SnackbarInfo';
import { dictStep, dictProcURL, dictProcListURL } from '../utils/dicts';
import { getMachines, checkAuthStep } from '../utils/funcs';
import ButtonProcHis from '../modules/ButtonProcHis';
//  数値入力GridItem + InputNumber
const GI = ({ control, label, name, ...rest }: any) => (
  <Grid item xs={6} sm={3} md={2}>
    <InputNumber control={control} label={label} name={name} {...rest} />
  </Grid>
);

//  時間入力用
const GT = ({ control, label, name, ...rest }: any) => (
  <Grid item xs={6} sm={3} md={3}>
    <InputTime control={control} label={label} name={name} {...rest} />
  </Grid>
);

//  底針日報登録項目のTYPE
type TypeProc = {
  date_work: Date;
  item: string;
  mst_machine: string;
  qty_in: number;
  qty_ok: number;
  qty_ng1: number;
  qty_ng2: number;
  qty_ng4: number;
  qty_rest: number;
  worker: string;
  num_workers: number | undefined;
  memo: string;
  time_start: Date;
  time_end: Date;
  time_working: number;
  time_setup: number;
  time_operating: number;
  time_suspend1: number;
  time_suspend2: number;
  time_suspend3: number;
  time_suspend4: number;
  time_suspend5: number;
  time_suspend6: number;
};
// Resolver用のschema定義
const schema = yup.object().shape({
  item: yupStrReq,
  date_work: yupDateReq,
  mst_machine: yupNumReq,
  qty_in: yupNum,
  time_start: yupDate,
  time_end: yupDate,
});

const ProcDivider = ({ children }: { children: ReactChild }) => (
  <Divider textAlign="left" sx={{ my: 2 }}>
    {children}
  </Divider>
);

//  stepフラグによる

//  デバッグフラグ
const debug = false;

//  コンポーネント!!! - - - - - - - - - - - - - - -
export default function App({ step }: { step: string }) {
  //  useFormでフォームの設定
  const { control, handleSubmit, setValue, getValues, trigger, reset } = useForm<TypeProc>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: { date_work: new Date(), item: '' },
  });

  //  BottomNaviで一覧に移動する際に使うnavigate
  const navigate = useNavigate();

  //  useWatchでtime_startとtime_endを監視しておく
  const timeStart = useWatch({ name: 'time_start', control });
  const timeEnd = useWatch({ name: 'time_end', control });

  //  初期ロード処理時に自動計算を動かしたくないのでフラグを立てておく
  const [enableCalc, setEnableCalc] = useState(false);

  //  不良詳細合計
  const [sumNG, setSumNG] = useState(0);
  //  routerのuseParamsでproc.idを取得
  const params = useParams();
  //  routerのuseSearchParams
  const [searchParams] = useSearchParams();

  let showProcBottom = false;
  if (step === '3.0' || step === '20.0') {
    showProcBottom = true;
  }

  // 実働時間(time_operating)の計算
  const calcTimeOperating = useCallback(() => {
    const values = getValues();

    if (values.time_working > 0) {
      setValue(
        'time_operating',
        values.time_working -
          Number(values.time_suspend1) -
          Number(values.time_suspend2) -
          Number(values.time_suspend3) -
          Number(values.time_suspend4) -
          Number(values.time_suspend5) -
          Number(values.time_suspend6)
      );
    }
  }, [getValues, setValue]);

  //  useWatchで監視しているtimeStart/Endの値が変わったら経過分数をセット
  useEffect(() => {
    if (dateFns.isValid(timeStart) && dateFns.isValid(timeEnd) && enableCalc) {
      console.log(timeEnd);
      console.log(timeStart);
      const timeWorking = dateFns.differenceInMinutes(timeEnd, timeStart);
      console.log('tw=' + timeWorking);
      if (timeWorking > 0) {
        //  5分刻み
        setValue('time_working', Math.round(timeWorking / 5) * 5);
      } else {
        setValue('time_working', 0);
      }
      calcTimeOperating();
    }
  }, [timeStart, timeEnd, setValue, enableCalc, calcTimeOperating]);

  //  useWatchで監視しててtimeStartが変わったら段取り時間をセット
  useEffect(() => {
    const machine = getValues('mst_machine');

    if (dateFns.isValid(timeStart) && machine && enableCalc) {
      (async () => {
        //  ここで段取り時間を取得
        const time_start = dateFns.format(timeStart, 'HH:mm:ss');
        const date_work = dateFns.format(getValues('date_work'), 'yyyy-MM-dd');
        const res = await loadData('get_time_setup.php', {
          time_start: time_start,
          machine: machine,
          date_work: date_work,
        });
        if (res.success) {
          setValue('time_setup', res.data.time_setup);
        }
      })();
    }
  }, [timeStart, getValues, setValue, enableCalc]);

  //  LoadingContext
  const { setIsLoading } = useContext(LoadingContext);
  //  ErrorDialogContextからshowErrorMessageを取得
  const { showErrorMessage } = useContext(ErrorDialogContext);

  //  AuthContextから取得した作業機械の配列
  //const [machines, setMachines] = useState([]);
  const { masters, user } = useContext(AuthContext);
  const machines = getMachines(masters.machines, step);

  //  snackbarのuseState
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (!checkAuthStep(user.flag_admin, step)) {
      navigate('/');
    }
    if (params.id) {
      setIsLoading(true);
      (async () => {
        const res = await loadFormData('load_proc.php', { id: params.id, step: step });
        setIsLoading(false);
        if (res.success) {
          //  time_startをparse
          if (res.data.data.time_start) {
            res.data.data.time_start = dateFns.parse(res.data.data.time_start, 'HH:mm:ss', new Date());
          }
          if (res.data.data.time_end) {
            res.data.data.time_end = dateFns.parse(res.data.data.time_end, 'HH:mm:ss', new Date());
          }
          res.data.data.date_work = dateFns.parse(res.data.data.date_work, 'yyyy-MM-dd', new Date());
          //  reset()メソッドで初期データをセット
          reset(res.data.data);
          //  自動計算機能を有効化
          setEnableCalc(true);
          // time_operatingは保存されないので再計算
          calcTimeOperating();

          if (showProcBottom) {
            calcSumNG();
          }
        } else {
          //  ローディングエラー表示
          showErrorMessage('データの呼び出しに失敗しました<br>' + res.msg);
        }
      })();
    } else {
      //  新規登録モード、初期化処理
      setIsLoading(true);
      setTimeout(() => {
        const values = getValues();
        reset({
          date_work: values.date_work,
          mst_machine: values.mst_machine,
          worker: values.worker,
        });

        let machineID: string | null = '';
        if (step === '20.0') {
          machineID = '49';
        } else {
          machineID = searchParams.get('m');
        }
        const dateWorkStr = searchParams.get('d');

        if (dateWorkStr) {
          const dateWork = dateFns.parse(dateWorkStr, 'yyyyMMdd', new Date());
          setValue('date_work', dateWork);
        }

        if (machineID) {
          setValue('mst_machine', machineID);
          setWorker(machineID);
        }
        setEnableCalc(true);
        setIsLoading(false);
      }, 500);
    }
  }, [params.id, searchParams, step, user]);

  //  submit処理
  const onSubmit: SubmitHandler<TypeProc> = (data) => {
    setIsLoading(true);
    //  一度anyでformDataに入れてから一部フォーマット変換
    const formData = { ...data } as any;

    //  日付と時間をフォーマット
    formData.time_end = data.time_end ? dateFns.format(data.time_end, 'HH:mm:ss') : null;
    formData.time_start = data.time_start ? dateFns.format(data.time_start, 'HH:mm:ss') : null;
    formData.date_work = data.date_work ? dateFns.format(data.date_work, 'yyyy-MM-dd') : null;
    //  step追加
    formData.step = step;

    //  asyncでデータ送信処理
    (async () => {
      const res = await sendFormData('save_proc.php', formData);
      //console.log(res);
      setIsLoading(false);

      if (res.success) {
        setOpenSnackbar(true);
        if (formData.id) {
          //  一覧から来て修正した場合・・・処理データでresetするか、一覧に戻るか？
        } else {
          navigate(dictProcURL[step] + '/' + res.data.proc_id);
          //  新規登録処理時はフォームリセット
          /*
          reset({
            mst_machine: data.mst_machine,
            worker: data.worker,
            date_work: data.date_work,
          });
          */
        }
      } else {
        //  サーバ処理エラー
        if (res.message) {
          showErrorMessage(res.message);
        } else {
          showErrorMessage('日報登録処理に失敗しました');
        }
      }
    })();
  };
  //  submitエラー処理
  const onSubmitError = () => {
    console.log('handleSubmit Error');
  };

  // 残数の自動計算
  const calcQtyRest = () => {
    const values = getValues();
    setValue(
      'qty_ng4',
      Number(values.qty_in) -
        Number(values.qty_ok) -
        Number(values.qty_ng1) -
        Number(values.qty_ng2) -
        Number(values.qty_rest)
    );
  };

  const [calcSumTimer, setCalcSumTimer] = useState<any>(null);

  const calcSumNG = () => {
    clearTimeout(calcSumTimer);
    const timer = setTimeout(() => {
      const values = getValues();
      let sum = 0;
      Object.entries(values).map(([key, value]) => {
        if (key.match(/^ng\d{3}$/)) {
          sum += Number(value);
        }
        return true;
      });
      setSumNG(sum);
    }, 500);
    setCalcSumTimer(timer);
  };

  //  machineオブジェクトから指定IDのmanagerを抜き出してworkerにセット
  const setWorker = (machineID: string) => {
    if (machineID) {
      const machine = machines.find((v: any) => String(v.id) === String(machineID));

      if (machine) {
        setValue('worker', machine['manager']);
        setValue('num_workers', machine['num_workers']);
      } else {
        setValue('worker', '');
        setValue('num_workers', undefined);
      }
    } else {
      setValue('worker', '');
      setValue('num_workers', undefined);
    }
  };

  //  ButtonProcHisに渡すgetItem
  const getItem = () => {
    return getValues('item');
  };
  return (
    <>
      <Container sx={{ height: '100%' }}>
        <Stack direction="row" sx={{ my: 2 }} spacing={1}>
          <Typography variant="h5" component="h2">
            {dictStep[step]}日報
          </Typography>
          {params.id ? (
            <Chip color="info" label="修正" size="small" />
          ) : (
            <Chip color="error" label="新規" size="small" />
          )}
        </Stack>

        <Box>
          {debug && (
            <>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  console.log(getValues());
                }}
              >
                Check Data
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const time = getValues('time_end');
                  console.log(time);
                  console.log(dateFns.format(time, 'HH:mm:ss'));
                }}
              >
                time
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setValue('time_end', dateFns.parse('23:33:11', 'HH:mm:ss', new Date()));
                }}
              >
                set time
              </Button>
            </>
          )}
        </Box>
        <ProcDivider>基本 *</ProcDivider>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={3}>
            <InputDate control={control} label="作業日" name="date_work" fullWidth required />
          </Grid>
          <Grid item xs={6} sm={4} md={6}>
            <InputSelect
              control={control}
              label="機械"
              name="mst_machine"
              items={machines}
              onChange={(e: any) => {
                const machineID = e.target.value;
                if (machineID) {
                  const machine = machines.find((v: any) => v.id === machineID);
                  //console.log(machine);
                  if (machine) {
                    setWorker(machineID);
                  } else {
                    setWorker('');
                  }
                } else {
                  setWorker('');
                }
              }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <InputText control={control} label="作業者" name="worker" fullWidth />
          </Grid>
          <Grid item md={5} xs={11}>
            <InputAutocompleteRemote
              control={control}
              setValue={setValue}
              trigger={trigger}
              onChange={(e: any, option: any) => {
                //console.log('onChangeCustommmm');
                //console.log(option);
              }}
              label="商品"
              name="item"
              urlSearch="search_item.php"
              required
              fullWidth
            />
          </Grid>
          <Grid item md={1} xs={1}>
            <ButtonProcHis getItem={getItem} />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputText control={control} label="備考" name="memo" fullWidth />
          </Grid>
        </Grid>
        <ProcDivider>作業数量</ProcDivider>
        <Grid container spacing={2}>
          {step !== '1.0' && <GI control={control} label="投入数" name="qty_in" required onChange={calcQtyRest} />}
          <GI control={control} label="良品数" name="qty_ok" required onChange={calcQtyRest} />
          <GI control={control} label="不良数" name="qty_ng1" required onChange={calcQtyRest} />
          {step !== '1.0' && (
            <>
              <GI control={control} label="前工程不良" name="qty_ng2" required onChange={calcQtyRest} />
              <GI control={control} label="残数" name="qty_rest" onChange={calcQtyRest} />
              <GI control={control} label="遺失数" name="qty_ng4" variant="filled" InputProps={{ readOnly: true }} />
            </>
          )}
        </Grid>
        <ProcDivider>作業時間</ProcDivider>
        <Grid container spacing={2}>
          <GI control={control} label="段取時間(分)" name="time_setup" />
          <GT control={control} label="開始" name="time_start" setValue={setValue} showNowButton={true} />
          <GT control={control} label="終了" name="time_end" setValue={setValue} showNowButton={true} />
          <GI
            control={control}
            label="作業時間(分)"
            name="time_working"
            variant="filled"
            InputProps={{ readOnly: true }}
          />
          <GI control={control} label="作業人数" name="num_workers" />
        </Grid>
        <ProcDivider>停止時間</ProcDivider>
        <Grid container spacing={2}>
          <GI control={control} label="1.故障" name="time_suspend1" onChange={calcTimeOperating} />
          <GI control={control} label="2.品質" name="time_suspend2" onChange={calcTimeOperating} />
          <GI control={control} label="3.手待" name="time_suspend3" onChange={calcTimeOperating} />
          <GI control={control} label="4.会議・清掃" name="time_suspend4" onChange={calcTimeOperating} />
          <GI control={control} label="5.休止" name="time_suspend5" onChange={calcTimeOperating} />
          <GI control={control} label="6.休憩" name="time_suspend6" onChange={calcTimeOperating} />
          <GI
            control={control}
            label="実働時間(分)"
            name="time_operating"
            variant="filled"
            InputProps={{ readOnly: true }}
          />
        </Grid>
        {
          //  step3とstep20以外は不良詳細不要
          showProcBottom && (
            <>
              <ProcDivider>製造工程不良</ProcDivider>
              <Grid container spacing={2}>
                <GI control={control} label="本体(汚れ・切れ)" name="ng101" onChange={calcSumNG} />
                <GI control={control} label="給袋・整列" name="ng102" onChange={calcSumNG} />
                <GI control={control} label="送袋" name="ng103" onChange={calcSumNG} />
                <GI control={control} label="コンベア・反転機" name="ng104" onChange={calcSumNG} />
                <GI control={control} label="その他" name="ng106" onChange={calcSumNG} />
                <GI control={control} label="準備ロス" name="ng107" onChange={calcSumNG} />
              </Grid>

              <Divider textAlign="left" sx={{ my: 2 }}>
                開封側不良
              </Divider>
              <Grid container spacing={2}>
                <GI control={control} label="45°曲がり" name="ng201" onChange={calcSumNG} />
                <GI control={control} label="底部成形不良" name="ng202" onChange={calcSumNG} />
                <GI control={control} label="底糊切れ・抜け" name="ng203" onChange={calcSumNG} />
                <GI control={control} label="化粧紙なし・剥がれ" name="ng204" onChange={calcSumNG} />
                <GI control={control} label="化粧紙上下ズレ" name="ng205" onChange={calcSumNG} />
                <GI control={control} label="化粧紙左右ズレ" name="ng206" onChange={calcSumNG} />
                <GI control={control} label="化粧紙シワ・破れ" name="ng207" onChange={calcSumNG} />
                <GI control={control} label="化粧紙糊漏れ" name="ng208" onChange={calcSumNG} />
                <GI control={control} label="化粧紙糊切れ" name="ng209" onChange={calcSumNG} />
                <GI control={control} label="CT無し・ズレ" name="ng210" onChange={calcSumNG} />
                <GI control={control} label="CT切れ・折れ" name="ng211" onChange={calcSumNG} />
                <GI control={control} label="底印刷不良" name="ng212" onChange={calcSumNG} />
              </Grid>

              <Divider textAlign="left" sx={{ my: 2 }}>
                口封側不良
              </Divider>
              <Grid container spacing={2}>
                <GI control={control} label="45°曲がり" name="ng301" onChange={calcSumNG} />
                <GI control={control} label="底部成形不良" name="ng302" onChange={calcSumNG} />
                <GI control={control} label="底糊切れ・抜け" name="ng303" onChange={calcSumNG} />
                <GI control={control} label="化粧紙なし・剥がれ" name="ng304" onChange={calcSumNG} />
                <GI control={control} label="化粧紙上下ズレ" name="ng305" onChange={calcSumNG} />
                <GI control={control} label="化粧紙左右ズレ" name="ng306" onChange={calcSumNG} />
                <GI control={control} label="化粧紙シワ・破れ" name="ng307" onChange={calcSumNG} />
                <GI control={control} label="化粧紙糊漏れ" name="ng308" onChange={calcSumNG} />
                <GI control={control} label="化粧紙糊切れ" name="ng309" onChange={calcSumNG} />
                <GI control={control} label="メルト無し・欠け" name="ng310" onChange={calcSumNG} />
                <GI control={control} label="メルト筋" name="ng311" onChange={calcSumNG} />
                <GI control={control} label="メルト塗布量" name="ng312" onChange={calcSumNG} />
              </Grid>
              <Divider textAlign="left" sx={{ my: 2 }}>
                第1バルブ
              </Divider>
              <Grid container spacing={2}>
                <GI control={control} label="無し" name="ng401" onChange={calcSumNG} />
                <GI control={control} label="ズレ" name="ng402" onChange={calcSumNG} />
                <GI control={control} label="破れ" name="ng403" onChange={calcSumNG} />
                <GI control={control} label="糊漏れ" name="ng404" onChange={calcSumNG} />
                <GI control={control} label="糊切れ" name="ng405" onChange={calcSumNG} />
                <GI control={control} label="口径不良" name="ng406" onChange={calcSumNG} />
              </Grid>
              <Divider textAlign="left" sx={{ my: 2 }}>
                第2バルブ
              </Divider>
              <Grid container spacing={2}>
                <GI control={control} label="無し" name="ng501" onChange={calcSumNG} />
                <GI control={control} label="ズレ" name="ng502" onChange={calcSumNG} />
                <GI control={control} label="破れ" name="ng503" onChange={calcSumNG} />
                <GI control={control} label="糊漏れ" name="ng504" onChange={calcSumNG} />
                <GI control={control} label="糊切れ" name="ng505" onChange={calcSumNG} />
              </Grid>
              <Divider textAlign="left" sx={{ my: 2 }}>
                インナーパッチ
              </Divider>
              <Grid container spacing={2}>
                <GI control={control} label="無し" name="ng601" onChange={calcSumNG} />
                <GI control={control} label="ズレ" name="ng602" onChange={calcSumNG} />
                <GI control={control} label="破れ" name="ng603" onChange={calcSumNG} />
                <GI control={control} label="糊漏れ" name="ng604" onChange={calcSumNG} />
                <GI control={control} label="糊切れ" name="ng605" onChange={calcSumNG} />
              </Grid>
            </>
          )
        }

        <Box sx={{ height: 100 }}></Box>
      </Container>
      <FixedBottomNavi>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            {showProcBottom && (
              <TextField
                label="不良詳細合計"
                size="small"
                sx={{ m: 1, width: 140 }}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
                value={sumNG}
              />
            )}
          </Box>
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{ m: 1 }}
            startIcon={<ListIcon />}
            onClick={() => {
              //  作業日と機械をキープしてListに
              const mstMachine = getValues('mst_machine');
              const dateWork = getValues('date_work');
              let path = dictProcListURL[step] + '?d=' + dateFns.format(dateWork, 'yyyyMMdd');
              if (mstMachine) {
                path += '&m=' + mstMachine;
              }
              navigate(path);
            }}
          >
            一覧
          </Button>
          {params.id && (
            <ButtonNew
              variant="contained"
              color="error"
              sx={{ m: 1 }}
              onClick={() => {
                const dateWork = getValues('date_work');
                const machineID = getValues('mst_machine');
                if (machineID) {
                  navigate(dictProcURL[step] + '?d=' + dateFns.format(dateWork, 'yyyyMMdd') + '&m=' + machineID);
                } else {
                  navigate(dictProcURL[step] + '?d=' + dateFns.format(dateWork, 'yyyyMMdd'));
                }
                //reset
                //navigate(0);
              }}
            >
              新規モード
            </ButtonNew>
          )}

          <Button
            variant="contained"
            color="warning"
            size="large"
            sx={{ m: 1 }}
            startIcon={<CancelIcon />}
            onClick={() => {
              const values = getValues();
              reset({
                mst_machine: values.mst_machine,
                date_work: values.date_work,
                worker: values.worker,
              });
              setSumNG(0);
            }}
          >
            リセット
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ m: 1 }}
            startIcon={<SaveIcon />}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          >
            保存
          </Button>
        </Box>
      </FixedBottomNavi>
      <SnackbarInfo
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        日報登録が完了しました
      </SnackbarInfo>
    </>
  );
}
