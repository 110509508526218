/**
 * hhttps://mui.com/material-ui/react-bottom-navigation/
 * のFixed Positioningから
 */
import { AppBar } from '@mui/material';
import { ReactNode } from 'react';
import { green } from '@mui/material/colors';
import { Container } from '@mui/system';

export default function FixedBottomNavi({ children, ...rest }: { children: ReactNode; [key: string]: any }) {
  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, zIndex: 99, bgcolor: green[50] }}>
      <Container maxWidth="xl">{children}</Container>
    </AppBar>
  );
}
