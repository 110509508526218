/**
 * AuthFrame
 * ログイン後のレイアウトの外側 - childrenじゃなくてrouter v6のOutletで配置
 */
import { Container, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './components/AppBar';
import SubProcAppBar from './components/AppBarSubProc';
import theme from '../theme';
import { APP_MODE } from '../config/';
//import Div100vh from 'react-div-100vh';

const AuthFrame = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        {APP_MODE === 'factory' && <ResponsiveAppBar />}
        {APP_MODE === 'subproc' && <SubProcAppBar />}

        <Container sx={{ mt: '86px' }}>
          <Outlet />
        </Container>
      </ThemeProvider>
    </>
  );
};
export default AuthFrame;
