import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { MenuItem, TextField, TextFieldProps, Autocomplete, Button, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useState } from 'react';
import { request } from '../apis/request';
import { debounce } from 'lodash';
import { setSeconds, addMinutes, setMinutes } from 'date-fns';

/* Inputに用の基本Type */
type TypeProps = {
  label: string;
  name: string;
  control: any;
  onChange?: any;
  [key: string]: any; //  ...props用
};
/**
 * https://qiita.com/yyos/items/6ed6d5babefb3702956b を参考にDateFieldコンポーネント
 * @param props
 * @returns
 */
export const InputDate = ({
  name,
  label,
  control,
  readOnly = false,
  onChange = (newDate: any) => {},
  ...rest
}: TypeProps & { readOnly?: boolean }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, fieldState }) => {
        return (
          <DatePicker
            {...field}
            label={label}
            mask="____/__/__"
            inputFormat="yyyy/MM/dd"
            readOnly={readOnly}
            onChange={(newDate: any) => {
              onChange(newDate);
              field.onChange(newDate);
            }}
            renderInput={(params: TextFieldProps) => {
              return (
                <TextField {...params} error={fieldState.invalid} helperText={fieldState.error?.message} {...rest} />
              );
            }}
          />
        );
      }}
    />
  );
};
/*
 * TimePickerコンポーネント
 */
export const InputTime = ({
  name,
  label,
  control,
  setValue,
  readOnly = false,
  showNowButton = false,
  ...rest
}: TypeProps & { setValue: any; readOnly?: boolean; showNowButton?: boolean }) => {
  /*
  const setNow = () => {
    setValue(name, new Date());
  };
  */
  const setNow = () => {
    const date = new Date();
    //console.log(date);
    const roundedMinute = Math.round(date.getMinutes() / 5) * 5; // 分を5で割って切り捨て、5で乗算する
    //console.log(roundedMinute);
    //let roundedDate = startOfMinute(date); // 秒やミリ秒を0にする
    const roundedDate = setSeconds(setMinutes(date, 0), 0);
    //console.log(roundedDate);
    //console.log(addMinutes(roundedDate, roundedMinute));
    setValue(name, addMinutes(roundedDate, roundedMinute));
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, fieldState }) => {
        return (
          <TimePicker
            {...field}
            label={label}
            mask="__:__"
            inputFormat="HH:mm"
            readOnly={readOnly}
            minutesStep={5}
            renderInput={(params: TextFieldProps) => {
              return (
                <Box sx={{ display: 'flex' }}>
                  <TextField {...params} error={fieldState.invalid} helperText={fieldState.error?.message} {...rest} />
                  {showNowButton && (
                    <Button variant="outlined" onClick={setNow} sx={{ height: 56 }}>
                      NOW
                    </Button>
                  )}
                </Box>
              );
            }}
          />
        );
      }}
    />
  );
};

/* AutoCompleteをControllerで括る */
type TypeInputAutocompleteProps = {
  label: string;
  name: string;
  control: any;
  setValue: any;
  trigger: any;
  options: string[];
  [key: string]: any; //  ...props用
};
/* AutoComplete - options渡しパターン */
export const InputAutocomplete = ({
  label,
  name,
  control,
  setValue,
  trigger,
  options = [],
  ...rest
}: TypeInputAutocompleteProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            options={options}
            {...field}
            freeSolo
            onChange={(e: any, newValue) => {
              //console.log('onChange');
              //  dummy
            }}
            onInputChange={(e: any, newValue, reason) => {
              //  console.log('onInputChange');
              setValue(name, newValue);
              trigger(name);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label={label}
                  {...rest}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

/* AutoComplete - リモートサーバから */
export const InputAutocompleteRemote = ({
  label,
  name,
  control,
  trigger,
  urlSearch,
  setValue,
  onChange = (e: any, option: any) => {},
  params = {},
  ...rest
}: any) => {
  //  表示候補のoptions
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [open, setOpen] = useState(false);
  //const [inputValue, setInputValue] = useState('');

  //  axiosに入力文字を送ってoptionsにするデータを取得
  const searchOptions = async (newValue: string) => {
    setLoading(true);
    const res = await request(urlSearch, { query: newValue });
    setLoading(false);

    console.log(res);

    if (res.success) {
      setOptions(res.data);
    } else {
      setOptions([]);
    }
  };

  //  debounceを使って入力止まったタイミングでリモートにアクセスしにいく
  const debounceSearch = debounce((newValue: string) => searchOptions(newValue), 500);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            options={options}
            loading={loading}
            {...field}
            freeSolo
            //inputValue={inputValue}
            onChange={(e: any, option) => {
              //console.log('onChange');
              //console.log(option);

              if (option) {
                setValue(name, option.label);
                onChange(e, option);
              }
              //field.onChange(e);
              trigger(name);
              setOptions([]);
            }}
            onInputChange={(e: any, newValue, reason) => {
              //console.log(reason);
              if (reason === 'input') {
                debounceSearch(newValue);
              }
              //setInputValue(newValue);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label={label}
                  {...rest}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

type TypeInputSelectProps = {
  label: string;
  name: string;
  control: any;
  items: { [k: string]: any } | any[];
  [key: string]: any;
  onChange?: any;
};
/* Selectフォーム(Textコンポーネントのselectオプション) */
export const InputSelect = ({
  label,
  control,
  name,
  items = [],
  onChange = (e: any) => undefined,
  ...rest
}: TypeInputSelectProps) => {
  if (!items) {
    items = {}; //  指定無い場合のエラー回避
    console.log('items is NULL!!!');
  }
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field, fieldState }) => {
        return (
          <TextField
            label={label}
            {...field}
            onChange={(e: any) => {
              field.onChange(e);
              onChange(e);
            }}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            fullWidth
            select
            {...rest}
          >
            {Array.isArray(items)
              ? items.map((v) => {
                  return (
                    <MenuItem key={v.id} value={v.id}>
                      {v.name}
                    </MenuItem>
                  );
                })
              : Object.entries(items).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })}
          </TextField>
        );
      }}
    />
  );
};

/* TextFieldのwrapper */
export const InputText = ({
  label = '',
  name,
  control,
  onChange = (e: any) => undefined,
  ...rest
}: TypeProps & { onChange?: any }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            onChange={(e: any) => {
              field.onChange(e);
              onChange(e);
            }}
            label={label}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            {...rest}
          />
        );
      }}
    />
  );
};

export const InputNumber = ({
  label,
  name,
  control,
  onChange = (e: any) => undefined,
  ...rest
}: TypeProps & { onChange?: any }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            onChange={(e: any) => {
              field.onChange(e);
              onChange(e);
            }}
            type="number"
            label={label}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            {...rest}
          />
        );
      }}
    />
  );
};

export const InputPassword = ({ label, name, control, ...rest }: TypeProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            type="password"
            label={label}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
