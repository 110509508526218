import { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from '../lib/yup';
import { AuthContext } from '../providers/AuthProvider';
import { InputPassword, InputText } from '../elements/InputsHookForm';
//import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import LogoIcon from '../elements/LogoIcon';
//import { blue } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { APP_TITLE } from '../config';

const yupSchema = yup.object().shape({
  login_id: yup.string().required(),
  password: yup.string().required(),
});
type TypeItems = {
  login_id: string;
  password: string;
};

export default function SignIn() {
  //  AuthContextからlogin取り出し
  const { fnLogin } = useContext(AuthContext);

  //  ReactHookFormのuseForm
  const { control, handleSubmit, setError } = useForm<TypeItems>({ resolver: yupResolver(yupSchema) });

  const onSubmit: SubmitHandler<TypeItems> = (data) => {
    //  AuthContextのloginメソッドでログイン
    fnLogin(data.login_id, data.password, () => {
      setError('password', { type: 'server', message: 'ログインパスワードが一致しません' });
    });
  };
  const onError = () => {
    //console.log('handlesubmit err');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="xs"
        sx={{ height: '100vh', display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}
      >
        <Paper
          elevation={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LogoIcon sx={{ color: 'white' }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            {APP_TITLE}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mt: 1 }}>
            <InputText required label="ログインID" name="login_id" control={control} fullWidth sx={{ my: 1 }} />
            <InputPassword required label="パスワード" name="password" control={control} sx={{ my: 1 }} fullWidth />
            <Button type="submit" fullWidth variant="contained" size="large" sx={{ mt: 3, mb: 2 }}>
              ログイン
            </Button>
          </Box>
        </Paper>
        {/* <Copyright /> */}
      </Container>
    </ThemeProvider>
  );
}
